<template>
  <skeleton-loading v-if="loading">
    <row
      :gutter="{top: '10px'}"
    >
      <column
        v-for="i in 5"
        :key="i"
        :span="10"
        :gutter="10"
      >
        <square-skeleton
          :count="1"
          :box-properties="{
            height: '80px',
          }"
        />
      </column>
    </row>
  </skeleton-loading>
  <div
    v-else
    class="kpi-row"
  >
    <kpi
      :value="$n(Math.max(0, client.total_credit) || 0, 'currency')"
      label="Crédito total"
    />
    <kpi
      :value="$n(client.current_credit || 0, 'currency')"
      label="Crédito disponível"
    />
    <kpi
      :value="client.due_day || 'N/D'"
      label="Dia do vencimento"
    />
    <kpi
      :value="$n(Math.max(0, totalInCurrentStatement) || 0, 'currency')"
      label="Próxima fatura"
    />
    <kpi
      :value="$n(Math.max(0, totalToBePayed) || 0, 'currency')"
      label="Total a receber"
    />
  </div>
</template>

<script>
import Kpi from "@/components/Kpi";

export default {
  components: {
    Kpi,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    client: {
      type: [Object, Boolean],
      default: () => {},
    }
  },
  computed: {
    totalToBePayed() {
      const statements = this.client.user_statements;

      if (!statements) {
        return 0;
      }
      const open =
        (statements.open_statement &&
          +statements.open_statement.total_remaining_amount) ||
        0;
      const future =
        (statements.future_statement &&
          +statements.future_statement.total_remaining_amount) ||
        0;
      return open + future;
    },
    totalInCurrentStatement() {
      const statements = this.client.user_statements;

      if (!statements) {
        return 0;
      }

      const closed =
        (statements.closed_statement &&
          +statements.closed_statement.total_remaining_amount) ||
        0;

      return closed;
    },
  }
}
</script>

<style lang="scss" scoped>
  .kpi-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 16px;
    margin-bottom: 32px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 16px;
    }
    @media screen and (max-width: 425px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 16px;
    }
    @media screen and (max-width: 375px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 16px;
    }
  }
</style>
