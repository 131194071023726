<template>
  <el-card class="detalhes-venda">

    <span class="input-label centered">Valor total da venda</span>
    <div class="amount-input">
      <money ref="input" v-model="saleAmount" v-bind="$money" class="el-input__inner" />
    </div>

    <span class="input-label centered">
      Plano e parcelas
      <el-popover
        ref="plans"
        :tabindex="-1"
        placement="bottom"
        width="200"
        trigger="hover"
        content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
      />
      <i
        v-popover:plans
        id="removeFocus"
        :tabindex="-1"
        class="el-icon el-icon-question plans-icon"
      />
    </span>
    <plans-combo :plans="client.plans" @onInstallmentSelect="updatePlanAndInstallments" />
    <span class="input-label centered">
      Primeira parcela
      <el-popover
        ref="plans"
        :tabindex="-1"
        placement="bottom"
        width="200"
        trigger="hover"
        content="Você poderá simular/alterar o plano e a quantidade de parcelas na próxima tela, antes de confirmar a venda."
      />
      <i
        v-popover:plans
        id="removeFocus"
        :tabindex="-1"
        class="el-icon el-icon-question plans-icon"
      />
    </span>
    <repay-due-date-combo :user="client" @onDateSelect="val => first_due_date = val" />
    <!-- <div class="inline-switch">
      <span class="input-label">Entrada</span>
      <el-switch
        v-model="showDownPayment"
        class="down-payment-switch"
        active-text="Sim"
        inactive-text="Não"
      />
    </div>-->

    <template v-if="showDownPayment">
      <span class="input-label">Valor de entrada:</span>
      <payment-combo v-model="saleDownPayment" :money="$money" class="detalhes-venda__combo" />
    </template>

    <div class="inline-switch">
      <span class="input-label">Descrição</span>
      <el-switch
        v-model="showDescription"
        class="down-payment-switch"
        active-text="Sim"
        inactive-text="Não"
      />
    </div>
    <el-input
      v-if="showDescription"
      v-model="saleDescription"
      style="resize: vertical; margin-bottom: 1rem;"
      type="textarea"
    />

    <!-- <template v-if="insuficientCredit">
      <el-alert
        :closable="false"
        class="client-alert"
        title="Crédito insuficiente"
        description="Tente aumentar o valor da entrada."
        type="error"
        show-icon
      />
      <br />
    </template>-->

    <confirm-phone-alert v-if="!client.phone_confirmed" />

    <el-button
      :loading="submitting"
      :disabled="!isSaleValid"
      class="submit-button"
      type="primary"
      round
      size="small"
      @click="handleSubmit"
    >Reparcelar</el-button>
  </el-card>
</template>

<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import PlansCombo from "@/components/PlansCombo";
import RepayDueDateCombo from "@/components/RepayDueDateCombo";
import PaymentCombo from "@/components/PaymentCombo";
import RealizarConsulta from "@/containers/RealizarConsulta";
import CompletarCadastro from "@/containers/CompletarCadastro";
import ConfirmPhoneAlert from "@/components/ConfirmPhoneAlert";

export default {
  components: {
    Money,
    PlansCombo,
    RepayDueDateCombo,
    PaymentCombo,
    RealizarConsulta,
    CompletarCadastro,
    ConfirmPhoneAlert
  },
  props: {
    saleData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      submitting: false,
      showDownPayment: false,
      showDescription: false,

      saleAmount: 0,
      saleDescription: "",
      salePlan: false,
      saleInstallments: 1,
      saleDownPayment: [],
      saleDownPaymentLimit: 0,
      first_due_date: ""
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      details: types.SALE_DETAILS
    }),
    showSanitizeRegister() {
      if (this.client && this.client.neopag_status) {
        return this.client.neopag_status.id === 16;
      }
      return false;
    },
    showVerifyRegister() {
      if (this.client && this.client.store_status) {
        return this.client.store_status.id === 5;
      }
      return false;
    },
    saleDownPaymentSum() {
      return this.saleDownPayment && this.saleDownPayment.length
        ? this.saleDownPayment.map(it => it.amount).reduce((a, b) => a + b)
        : 0;
    },
    saleRealAmount() {
      return this.saleAmount - this.saleDownPaymentSum;
    },
    insuficientCredit() {
      const limit = this.client.current_credit;
      return this.saleRealAmount > limit;
    },
    isSaleValid() {
      const rule1 = this.saleAmount > 0;
      const rule2 = !!this.salePlan;
      // const rule3 = !this.insuficientCredit;
      const rule4 = this.saleRealAmount > 0;
      const rule5 = this.saleDownPaymentLimit
        ? this.saleDownPaymentSum >=
          (this.saleDownPaymentLimit / 100) * this.saleAmount
        : true;
      const rule6 = this.saleInstallments > 0;
      return rule1 && rule2 && rule4 && rule5 && rule6;
    }
  },
  mounted() {
    if (this.$refs.input) {
      this.$refs.input.$el.focus();
    }
    this.saleAmount = this.details.amount ? this.details.amount : 0;
    if (this.details.downPayment && this.details.downPayment.length > 0) {
      this.saleDownPayment = this.details.downPayment;
      this.showDownPayment = true;
    }
    if (this.details.description) {
      this.saleDescription = this.details.description;
      this.showDescription = true;
    }
    if (this.details.plan) {
      this.saleDownPaymentLimit = this.details.plan.down_payment;
      this.salePlan = this.details.plan;
    }
    this.saleAmount = this.saleData.sale.amount;
    // document.addEventListener("keydown", this.handleKeyboardNavigation);
  },
  beforeDestroy() {
    // document.removeEventListener("keydown", this.handleKeyboardNavigation);
  },
  methods: {
    handleKeyboardNavigation(event) {
      if (event.key === "Enter" && this.isSaleValid) {
        this.handleSubmit();
      }
    },
    updatePlanAndInstallments(installment) {
      this.saleInstallments = installment.installment;
      this.saleDownPaymentLimit = installment.plan.down_payment;
      this.salePlan = this.client.plans.find(
        it => it.id === installment.plan.id
      );
    },
    async handleSubmit() {
      this.submitting = true;
      await this.$store.dispatch(types.SALE_DETAILS, {
        valid: true,
        plan: this.salePlan,
        amount: this.saleAmount,
        description: this.saleDescription,
        downPayment: this.saleDownPayment,
        installments: this.saleInstallments,
        downPaymentSum: this.saleDownPaymentSum || 0,
        first_due_date: this.first_due_date
      });
      this.submitting = false;
      this.$emit("repaySummary");
    }
  }
};
</script>

<style scoped lang="scss">
.centered {
  text-align: center;
}
.amount-input {
  input {
    margin: 1rem 0;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 38px !important;
    font-family: Montserrat;
    font-weight: 700;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    color: $laranja;
    outline: none;
    padding-bottom: 4px;
  }
}
.detalhes-venda {
  width: 90%;
  max-width: 480px;
  margin: 0 auto;

  &__name {
    margin-bottom: 1rem;
  }

  &__limit {
    margin-bottom: 26px;
  }

  &__combo {
    margin-top: 0;
  }

  .inline-switch {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .plans-icon {
    color: $laranja;
    cursor: pointer;
  }

  .input-label {
    display: block;
    margin-bottom: 0;
    font-size: 0.9375rem;
  }

  .el-switch__label * {
    font-size: 0.9375rem;
  }

  .el-input,
  .el-select {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .submit-button {
    width: 100%;
  }
}
</style>
