<template>
	<section class="register-documents">
		<template v-if="documentProgress !== 101">
			<el-progress :percentage="documentProgress" status="exception" />
			<p class="progress-label" v-html="progressLabel" />
			<div :loading="cameraLoading" class="dialog-preview" @click="$refs.fileInput.click()">
				<i v-if="cameraLoading" style="font-size: 78px; margin-top: 32px;" class="el-icon-loading" />
				<video v-if="showVideo" ref="video" width="500" height="281" autoplay="true" />
				<img v-else-if="!files[step]" :src="dialogPlaceholder" class="placeholder" />
				<img v-else-if="files[step] && !showPreview" :src="files[step]" />
				<canvas v-if="showPreview" class="hackCanvas" ref="canvas" width="1280" height="720" />
				<canvas v-if="showPreview" ref="canvasView" :width="canvasWidth" height="281" />
			</div>
			<input
				ref="fileInput"
				class="file-input"
				type="file"
				accept="image/*"
				@change="handleFileUpload"
			/>
			<div class="actions-container">
				<div v-if="hasCameraSupport">
					<el-button
						v-if="!showVideo"
						:loading="cameraLoading"
						round
						type="primary"
						size="small"
						@click="getVideo"
					>Câmera</el-button>
					<el-button
						v-else
						:loading="cameraLoading"
						round
						size="small"
						type="primary"
						@click="capturePhoto"
					>Tirar foto</el-button>
				</div>
				<el-button
					:loading="cameraLoading"
					round
					size="small"
					type="primary"
					icon="el-icon-plus"
					@click="handleUpload"
				>Importar</el-button>
				<el-button
					:loading="uploadingImage"
					:disabled="!files[step]"
					round
					size="small"
					type="success"
					icon="el-icon-upload2"
					@click="documentsSent"
				>Enviar</el-button>
			</div>
		</template>
		<template v-else>
			<div class="upload-success">
				<Lottie
					:options="defaultOptions"
					:height="$isMobile ? 100 : 200"
					:width="$isMobile ? 100 : 200"
					v-on:animCreated="handleAnimation"
				/>
				<h2>As imagens estão sendo processadas.</h2>
			</div>
		</template>
	</section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import { setTimeout } from "timers";
import Lottie from "vue-lottie";
import * as animationData from "@/assets/check.json";

const isCameraSupported = () => {
	return !!(
		navigator.getUserMedia ||
		navigator.webkitGetUserMedia ||
		navigator.mozGetUserMedia ||
		navigator.msGetUserMedia
	);
};

export default {
	components: {
		Lottie
	},
	props: {
		showDocumentDialog: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	data() {
		return {
			defaultOptions: {
				animationData: animationData.default,
				path: "../../assets/check.json",
				loop: false,
				autoplay: true,
				render: "svg"
			},
			alertChecked: false,
			showAlertDialog: false,
			documentProgress: 0,
			hasCameraSupport: isCameraSupported(),
			shareOption: "sms",
			shareValue: "",
			uploadingImage: false,
			showVideo: false,
			canvasWidth: 0,
			showPreview: false,
			cameraLoading: false,
			stream: [],
			sharing: {
				sms: false,
				email: false
			},
			tmpFormData: undefined,
			files: {
				type: "",
				front: "",
				verse: "",
				selfie: ""
			}
		};
	},
	watch: {
		showDocumentDialog: async function(val) {
			await this.onClose();
		}
	},
	computed: {
		...mapGetters({
			client: types.CLIENT_DETAILS
		}),
		isStoreDialogFullscreen() {
			return document.documentElement.clientWidth < 480;
		},
		step() {
			return this.documentProgress === 33
				? "front"
				: this.documentProgress === 66
				? "verse"
				: this.documentProgress === 100
				? "selfie"
				: "end";
		},
		progressLabel() {
			if (this.step === "front") {
				return "Passo 1 de 3: Envie uma foto legível da frente do documento de identificação do cliente";
			} else if (this.step === "verse") {
				return "Passo 2 de 3: Envie uma foto legível do verso do documento de identificação do cliente";
			}
			return "Passo 3 de 3: Envie uma <i>foto</i> do rosto do cliente<br/><br/>";
		},
		dialogPlaceholder() {
			if (this.step === "selfie") {
				return "/img/selfie.png";
			}
			return `/img/${this.files.type}--${this.step}.png`;
		}
	},
	created() {
		this.files.type = "rg";
		this.documentProgress = 33;
		this.onClose();
	},
	methods: {
		onClose() {
			this.showVideo = false;
			this.canvasWidth = 0;
			this.showPreview = false;
			if (isCameraSupported()) {
				if (typeof this.stream.getTracks === "function") {
					this.stream.getTracks().forEach(track => track.stop());
				}
			}
		},
		handleUpload() {
			this.onClose();
			this.$refs.fileInput.click();
		},
		async capturePhoto() {
			this.showPreview = true;
			setTimeout(() => {
				this.$refs.canvas
					.getContext("2d")
					.drawImage(this.$refs.video, 0, 0, 1280, 720);
				this.$refs.canvasView
					.getContext("2d")
					.drawImage(this.$refs.video, 0, 0, 500, 281);
				const callback = image => {
					this.tmpFormData = new FormData();
					this.files[this.step] = this.$refs.canvas.toDataURL("image/png");
					if (this.step === "selfie") {
						this.tmpFormData.append("document_type", "selfie");
						this.tmpFormData.append("document", image);
					} else {
						this.tmpFormData.append(
							"document_type",
							[this.files.type, this.step].join("_")
						);
						this.tmpFormData.append("document", image);
					}
				};
				this.$refs.canvas.toBlob(callback);
				this.stream.getTracks().forEach(track => track.stop());
				this.showVideo = false;
			}, 100);
		},
		async getVideo() {
			this.cameraLoading = true;
			this.showPreview = false;
			this.showVideo = true;
			setTimeout(async () => {
				try {
					this.canvasWidth = 500;
					const video = {
						width: 500,
						height: 281
					};
					if (this.$isMobile) {
						video.facingMode = { exact: "environment" };
					}
					let stream = await navigator.mediaDevices.getUserMedia({
						video,
						audio: false
					});
					this.stream = stream;
					this.$refs.video.srcObject = stream;
					this.cameraLoading = false;
				} catch (error) {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					this.$notify.error({ title: error.message });
				}
			}, 100);
		},
		handleFileUpload(event) {
			const input = event.target;
			if (input.files && input.files[0]) {
				const reader = new FileReader();
				this.tmpFormData = new FormData();
				reader.onload = e => {
					this.files[this.step] = e.target.result;
				};
				reader.readAsDataURL(input.files[0]);
			}
			if (this.step === "selfie") {
				this.tmpFormData.append("document_type", "selfie");
				this.tmpFormData.append("document", input.files[0]);
			} else {
				this.tmpFormData.append(
					"document_type",
					[this.files.type, this.step].join("_")
				);
				this.tmpFormData.append("document", input.files[0]);
			}
		},
		setDocumentType(type) {
			this.files.type = type;
			this.documentProgress = 33;
		},
		async documentsSent() {
			this.uploadingImage = true;
			if (this.step === "selfie") {
				try {
					await this.$store.dispatch(types.CLIENT_DOCUMENTS, this.tmpFormData);
					await this.$store.dispatch(types.CLIENT_UPDATE, {
						user: {
							id: this.client.id,
							documents_sent: 1
						}
					});
					this.notify("success", "Selfie");
					this.uploadingImage = false;
					this.documentProgress = 101;
					this.onClose();
				} catch (error) {
					if (this.$raven && error.status === 500) {
						this.$raven.captureException(error.message, { req: error });
					}
					// this.$notify.error({ title: error.message });
					this.notify("error", "Selfie");
					this.uploadingImage = false;
					this.onClose();
				}
			} else {
				try {
					await this.$store.dispatch(types.CLIENT_DOCUMENTS, this.tmpFormData);
					this.notify("success", "Documentos");
					this.uploadingImage = false;
					this.documentProgress = this.step === "front" ? 66 : 100;
					this.onClose();
				} catch (error) {
					this.notify("error", "Documentos");
					this.uploadingImage = false;
					this.onClose();
				}
			}
		},
		notify(type, title) {
			if (type === "error") {
				this.$notify.error({
					title,
					message: "Não foi possível subir essa imagem"
				});
			} else {
				this.$notify.success({
					title,
					message: "Upload realizado com sucesso!"
				});
			}
		},
		handleAnimation: function(anim) {
			this.anim = anim;
		}
	}
};
</script>

<style lang="scss">
@import "./src/styles/theme.scss";
.hackCanvas {
	display: none;
}
.register-documents {
	max-width: 500px;
	margin: 32px auto;

	@include large-up {
		padding-bottom: 0;
	}

	.el-step__head {
		height: 20px;
	}
	.el-dialog__body {
		padding-top: 0;
	}
	.el-progress__text {
		display: none;
	}
	.el-progress-bar {
		padding-right: 0;
		margin-right: 0;
	}

	.progress-label {
		line-height: 1.3;
		color: $preto;
	}

	.dialog-preview {
		text-align: center;
		max-width: 500px;
		margin: 0 auto;
		img {
			max-width: 500px;
			max-height: 281px;
			padding: 1rem;
			margin: 0 auto;
		}
		.placeholder {
			opacity: 0.5;
		}
		video {
			margin: 1rem 0;
		}
		canvas {
			margin: 1rem 0;
		}
	}
	.upload-success {
		text-align: center;

		h2 {
			margin: 1rem 0 2rem;
			line-height: 1.5;
		}
	}
	.actions-container {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		.el-button {
			width: 100%;
			margin: 0;
		}
		@media screen and (max-width: 425px) {
			grid-template-columns: 1fr;
		}
	}
	.file-input {
		display: none;
	}
	h3 {
		line-height: 1.45;
	}
	.document-type-chooser {
		padding-top: 1rem;
		text-align: center;
	}
	.client-dialog {
		text-align: center;
	}
	.btn-wrapper {
		text-align: center;
		margin-top: 1rem;
	}
}
</style>
