<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
				bottom: '15px',
				height: '15px',
				top: '20px'
			}"
      style="width: 100%"
    />
  </skeleton-loading>
  <section v-else>
    <el-dialog :fullscreen="$isMobile" :visible.sync="showModal">
      <h2 class="charge-dialog--title">
        {{
        chargeLogs.length > 1
        ? "Detalhes das cobranças"
        : "Detalhe da cobrança"
        }}
      </h2>
      <el-table :data="chargeLogs" style="width: 100%;">
        <el-table-column
          :formatter="(row, column) => formatDate(row.created_at)"
          sortable
          align="center"
          prop="created_at"
          label="Enviado em"
        />
        <el-table-column align="center" prop="comment" label="Mensagem" />
        <el-table-column
          align="center"
          prop="log_type"
          label="Canal"
          :formatter="(row) => formatLogTypes(row.log_type)"
        />
      </el-table>
    </el-dialog>
    <table-component :columns="columns" :data="charges" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";

import TableComponent from "@/components/Table";

export default {
  components: {
    TableComponent
  },
  data() {
    return {
      loading: true,
      showModal: false,
      chargeLogs: [],
      columns: [
        {
          key: "date",
          label: "Data da cobrança",
          render: date => (date ? moment(date).format("DD/MM/YYYY") : "")
        },
        {
          label: "Motivo da cobrança",
          render: item => `<span>${item.step.label}</span>`
        },
        {
          key: "actions",
          label: "Ações",
          offSet: -100,
          options: [
            {
              label: "Detalhes da cobrança",
              onPress: async item => {
                this.showModal = true;
                this.billetInformation = item;
                this.chargeLogs = item.logs;
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      charges: types.CLIENT_ACTIVITIES_ANSWERS,
      client: types.CLIENT_DETAILS
    })
  },
  watch: {
    client: async function(client) {
      this.loading = true;
      if (this.client.store_customer_id) {
        await this.$store
          .dispatch(types.CLIENT_ACTIVITIES_ANSWERS, client.store_customer_id)
          .catch(error => {
            if (this.$raven && error.status === 500) {
              this.$raven.captureException(error.message, { req: error });
            }
            this.$notify.error({ title: error.message });
          });
      }
      this.loading = false;
    }
  },
  async mounted() {
    this.loading = true;
    if (this.client.store_customer_id) {
      await this.$store
        .dispatch(
          types.CLIENT_ACTIVITIES_ANSWERS,
          this.client.store_customer_id
        )
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
    }
    this.loading = false;
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatLogTypes(value) {
      switch (value) {
        case "call":
          return "Ligação";
          break;

        case "sms":
          return "Mensagem SMS";
          break;

        case "email":
          return "E-mail";
          break;

        case "whatsapp":
          return "Mensagem WhatsApp";
          break;

        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.charge-dialog--title {
  color: $laranja;
  font-weight: bold;
}
</style>
