<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px',
      }"
      style="width: 100%"
    />
  </skeleton-loading>
  <section v-else>
    <el-dialog
      :fullscreen="$isMobile"
      :visible.sync="showInvoiceDialog">
      <section
        v-if="invoiceDetails"
        class="collapse-header">
        <h2>FATURA</h2>
        <div>
          <div class="wrapper">
            <span>Data de vencimento</span>
            <span class="content">{{ invoiceDetails.due_date | formatDate }}</span>
          </div>
          <div class="wrapper">
            <span>Total à pagar</span>
            <span class="content">{{ $n(invoiceDetails.total_remaining_amount, 'currency') }}</span>
          </div>
        </div>
      </section>
      <payment-list
        :data="invoiceDetails"
        :has-actions="false"
      />
    </el-dialog>
    <el-dialog
      :fullscreen="$isMobile"
      :visible.sync="showBilletsDialog">
      <h3>Boletos</h3>
      <el-table
        :data="activeBillets"
        style="width: 100%;">
        <el-table-column
          :formatter="(row, column) => $d(new Date(row.due_date + $tz), 'sale_summary')"
          sortable
          align="center"
          prop="due_date"
          label="Venc."
        />
        <el-table-column
          :formatter="(row, column) => $n(row.amount, 'currency')"
          sortable
          prop="amount"
          label="Valor"
        />
        <el-table-column
          prop="actions"
          aling="center"
          label="Ações">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-printer"
              @click="downloadBillet(scope.row.url)"
            >Baixar</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="actions"
          label="Status">
          <template slot-scope="scope">{{ handleStatus(scope.row.status) }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      :fullscreen="$isMobile"
      :visible.sync="showRedoDialog">
      <div
        v-if="activeInvoice"
        class="redo">
        <h3 v-if="activeInvoice.due_date">
          Data de vencimento atual:
          <b>{{ $d(new Date(activeInvoice.due_date + $tz), 'sale_summary') }}</b>
          <br >
          <br >
        </h3>
        <el-alert
          :closable="false"
          title="Atenção:"
        >Se nenhuma nova data de vencimento for selecionada, usaremos a data de vencimento da fatura</el-alert>
        <br >Nova data:&nbsp;&nbsp;
        <el-date-picker
          :picker-options="redoPickerOptions"
          v-model="redoDueDate"
          size="mini"
          format="dd/MM/yyyy"
          type="date"
        />
        <br >
        <br >
        <el-button
          :loading="redoing"
          type="success"
          @click="handleRedoBillet">Gerar</el-button>
        <el-button
          type="secondary"
          @click="showRedoDialog = false">Cancelar</el-button>
      </div>
    </el-dialog>
    <table-component
      :columns="columns"
      :data="invoices"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from 'moment';

import TableComponent from '@/components/Table';
import PaymentList from "@/components/Payment/PaymentSelector";

import toMoney from "@/helpers/toMoney";

export default {
  components: {
    TableComponent,
    PaymentList,
  },
  filters: {
    formatDate: date => date ? moment(date).format('DD/MM/YYYY') : '',
  },
  data() {
    const redoPickerShortcuts = [5, 10, 15, 30, 60, 90];
    return {
      loading: true,
      redoing: false,
      activeBillets: {},
      activeInvoice: {},
      showInvoiceDialog: false,
      showBilletsDialog: false,
      showRedoDialog: false,
      redoDueDate: "",
      redoPickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: "Hoje",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ].concat(
          ...redoPickerShortcuts.map(it => {
            return {
              text: `${it} dias`,
              onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() + 3600 * 1000 * 24 * it);
                picker.$emit("pick", date);
              }
            };
          })
        )
      },
      columns: [
        {
          key: 'due_date',
          label: 'Data',
          width: '100px',
          render: date => date ? moment(date).format('DD/MM/YYYY') : '',
        },
        {
          key: 'total_remaining',
          label: 'Valor Total',
          align: 'right',
          render: amount => `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(amount)}<span>`
        },
        {
          key: 'paid_amount',
          label: 'Valor Pago',
          align: 'right',
          render: amount => `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(amount)}<span>`
        },
        {
          key: 'remaining_amount',
          label: 'Restante',
          align: 'right',
          render: amount => `
          <span style="font-family: Roboto Mono; font-weight: 700; color: ${amount <= 0 ? '#56C779' : '#f1565d'}">
            ${toMoney(amount)}
          <span>`
        },
        {
          key: 'status',
          label: 'Status',
          render: status => `<span>${status === 'closed' ? 'Fechada' : 'Aberta'}</span>`
        },
        {
          key: 'actions',
          label: 'Ações',
          offSet: -100,
          options: [
            {
              label: 'Ver detalhes',
              onPress: async (item) => {
                await this.$store
                  .dispatch(types.CLIENT_INVOICE_DETAILS, item.id)
                  .catch(error => {
                    if (this.$raven && error.status === 500) {
                      this.$raven.captureException(error.message, { req: error });
                    }
                    this.$notify.error({ title: error.message });
                  });
                this.showInvoiceDialog = true;
              },
            },
            {
              label: 'Boletos',
              onPress: (item) => {
                this.showBilletsDialog = true;
                this.activeBillets = item.billets;
              },
              disabled: item => item.billets.length <= 0,
            },
            {
              label: 'Nova via',
              onPress: (item) => {
                this.showRedoDialog = true;
                this.activeInvoice = item;
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      invoices: types.CLIENT_INVOICES,
      invoiceDetails: types.CLIENT_INVOICE_DETAILS,
    }),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(types.CLIENT_INVOICES, this.$route.params.clientID)
    .catch((error) => {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    });
    this.loading = false;
  },
  methods: {
    async showInvoiceDetails(id) {
      await this.$store
        .dispatch(types.CLIENT_INVOICE_DETAILS, id)
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.showInvoiceDialog = true;
    },
    downloadBillet(url) {
      window.open(url, `_blank`);
    },
    handleStatus(status) {
      switch (status) {
        case "pending":
          return "Pendente";
        case "pending_registration":
          return "Pendente";
        case "registered":
          return "Registrado";
        case "paid":
          return "Pago";
        case "canceled":
          return "Cancelado";
        case "partially_paid":
          return "Parcialmente pago";
        case "refunded":
          return "Estornado";
        case "expired":
          return "Expirado";
      }
    },
    async handleRedoBillet() {
      this.redoing = true;
      await this.$store
        .dispatch(types.REMITTANCE_REDO_BILLETS, {
          statementID: this.activeInvoice.id,
          dueDate: moment(this.redoDueDate).format("YYYY-MM-DD"),
          clientID: this.$route.params.clientID
        })
        .then(() => {
          this.$notify.success("Nova via gerada com sucesso!");
        })
        .catch(error => {
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.showRedoDialog = false;
      this.redoing = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.collapse-header {
    display: flex;
    justify-content: space-between;
    @media(max-width: 767px) {
      flex-wrap: wrap;
    }
    align-items: center;
    padding-right: 14px;
    h2 {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      font-weight: 700;
    }
    .wrapper {
      display: inline-block;
      height: 48px;
      margin: 6px 0 0 12px;
      span {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        text-align: center;
        font-size: 14px;
        display: block;
        line-height: 14px;
        font-weight: 300;
      }
      .content {
        font-family: Montserrat;
        letter-spacing: 1.025px;
        color: $laranja;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }
  }
</style>
