<template>
  <client-profile />
</template>

<script>
import ClientProfile from "@/containers/Client/ClientProfile";
export default {
  components: {
    ClientProfile,
  },
}
</script>
