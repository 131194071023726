<template>
  <div>
    <div v-if="reason">
      <el-tooltip effect="light" :content="reason" placement="top-start">
        <div class="tag-container">
          <p :style="labelStyle" class="tag-label">{{ title }}</p>
          <p :style="{ backgroundColor: color, color: textColor }" class="tag-value">{{ value }}</p>
        </div>
      </el-tooltip>
    </div>
    <div v-else>
      <div class="tag-container">
        <p :style="labelStyle" class="tag-label">{{ title }}</p>
        <p :style="{ backgroundColor: color, color: textColor }" class="tag-value">{{ value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    reason: {
      type: String,
      default: ""
    },
    textColor: {
      type: String,
      default: ""
    },
    labelStyle: {
      type: [String, null],
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-container {
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  cursor: pointer;
}
.tag-label {
  // max-width: 100px;
  font-size: 0.6rem;
  white-space: nowrap;
}
.tag-value {
  width: 120px;
  border-radius: 60px;
  padding: 4px 8px;
  font-size: 0.6rem;
  margin-top: 4px;
  font-weight: 700;
}
</style>
