<template>
  <div>
    <div class="due-dates-wrapper" v-if="auth_details.permissions.change_first_installment_date">
      <el-button>Dia: {{this.user.user_statements.closed_statement.due_date | dueDay}}</el-button>
      <el-select v-model="dueMonth" placeholder="Mês">
        <el-option
          v-for="month in months"
          :key="month.id"
          :value="month.id"
          :label="month.name"
        >{{month.name}}</el-option>
      </el-select>
      <el-select v-model="dueYear" placeholder="Ano">
        <el-option v-for="year in years" :key="year.id" :value="year.date">{{year.date}}</el-option>
      </el-select>
    </div>
    <div class="first-date">
      <p>{{date}}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: {
    user: {
      type: [Object, null],
      default: () => {}
    }
  },
  data() {
    return {
      months: [
        { name: "Janeiro", id: 0 },
        { name: "Fevereiro", id: 1 },
        { name: "Março", id: 2 },
        { name: "Abril", id: 3 },
        { name: "Maio", id: 4 },
        { name: "Junho", id: 5 },
        { name: "Julho", id: 6 },
        { name: "Agosto", id: 7 },
        { name: "Setembro", id: 8 },
        { name: "Outubro", id: 9 },
        { name: "Novembro", id: 10 },
        { name: "Dezembro", id: 11 }
      ],
      dueMonth: "",
      years: [],
      dueYear: ""
    };
  },
  filters: {
    dueDay(day) {
      return moment(day, "YYYY-MM-DD").format("DD");
    }
  },
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    date() {
      const due_date = moment(
        this.user.user_statements.closed_statement.due_date
      ).format("DD");
      const showDate =
        this.dueYear && typeof this.dueMonth === "number"
          ? moment([this.dueYear, this.dueMonth, due_date]).format("DD/MM/YYYY")
          : "Selecione uma data válida";
      const dueDate = moment([this.dueYear, this.dueMonth, due_date]).format(
        "DD/MM/YYYY"
      );
      this.$emit("onDateSelect", dueDate);
      return showDate;
    }
  },
  mounted() {
    const { due_date } = this.user.user_statements.closed_statement;

    const month = this.months.filter(item => {
      return item.id === moment(due_date, "YYYY-MM-DD").format("MM") - 1;
    });
    this.dueMonth = month[0].id;
    this.dueYear = moment(due_date, "YYYY-MM-DD").format("YYYY");

    for (let i = -2; i <= 6; i++) {
      this.years.push({
        id: i,
        date: moment(due_date, "YYYY-MM-DD")
          .add(i, "year")
          .format("YYYY")
      });
    }
  }
};
</script>

<style lang="scss">
.due-dates-wrapper {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  .el-button + .el-button {
    margin-left: 0;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.first-date {
  text-align: center;
  margin: 0.5rem 0;
  p {
    font-size: 2rem;
    font-weight: bold;
    color: $laranja;
  }
}
</style>
