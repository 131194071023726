<template>
  <el-card class="sale-receipt">
    <i class="graphic el-icon-success" />
    <h2>Reparcelamento realizado</h2>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      details: types.SALE_DETAILS,
      installments: types.SALE_INSTALLMENTS
    })
  }
};
</script>

<style scoped lang="scss">
@import "./src/styles/theme.scss";

.sale-receipt {
  width: 90%;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;

  .graphic {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: darken($verde, 10);
  }
  h2 {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 400;
    line-height: 1.55;
  }
  .align-center {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-top: 1.5rem;
    text-align: center;
    .share-button,
    .submit-button {
      flex: 0 auto;
    }
  }
}
</style>
