<template>
  <div id="action-button-position">
    <section
      v-if="!mobile && options.length > 1"
      :class="{ 'action-buttons-wrapper-active': active }"
      :style="{ left: `${offSet}px` }"
      class="action-buttons-wrapper"
    >
      <el-button
        v-for="option in options"
        :key="option.label"
        :disabled="handleDisabled(item, option)"
        size="mini"
        round
        :type="option.label === 'Cancelar' ? 'danger' : 'success'"
        @click="onPress(item, option.onPress)"
      >
        {{ option.label }}
      </el-button>
    </section>
    <el-button
      v-else-if="options.length === 1"
      :key="options[0].label"
      :disabled="handleDisabled(item, options[0])"
      size="mini"
      :type="options[0].label === 'Cancelar' ? 'danger' : 'success'"
      @click="onPress(item, options[0].onPress)"
    >
      {{ options[0].label }}
    </el-button>
    <section
      v-else
      :class="{ 'action-buttons-wrapper-active': active }"
      :style="{ left: '5px', top: '35px' }"
      class="action-buttons-wrapper"
    >
      <el-button
        v-for="option in options"
        :key="option.label"
        :disabled="handleDisabled(item, option)"
        size="mini"
        round
        :type="option.label === 'Cancelar' ? 'danger' : 'success'"
        @click="onPress(item, option.onPress)"
      >
        {{ option.label }}
      </el-button>
    </section>
    <button
      v-if="options.length > 1"
      :disabled="disabled"
      :class="{ 'action-button-active': active, disabled: disabled }"
      class="action-button"
      round
      @click="active = !active"
      @blur="active = false"
    >
      <font-awesome-icon icon="plus" size="sm" style="color: #FAFAFA;" />
    </button>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPlus);

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    },
    offSet: {
      type: Number,
      default: -180
    },
    mobile: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    handleDisabled(item, option) {
      if (option.disabled) {
        return option.disabled(item);
      }
      return false;
    },
    onPress(item, callback) {
      this.active = false;
      callback(item);
    }
  }
};
</script>

<style lang="scss" scoped>

#action-button-position {
  @media screen and (max-width: 480px) {
    position: absolute;
  }

  position: relative;
}

.action-button {
  width: 30px;
  height: 30px;
  background-color: $laranja;
  border-radius: 15px;
  outline: none;
  border: none;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  &:hover {
    cursor: pointer;
  }
  transition: all 0.1s ease-in-out;
  svg {
    transition: all 0.1s ease-in-out;
  }
}
.disabled {
  opacity: 0.6;
}
.action-button-active {
  svg {
    transform: rotate(-45deg);
  }
}
.action-buttons-wrapper {
  transition: all 0.4s ease-in-out;
  display: flex;
  position: absolute;
  top: 0px;
  width: 180px;
  padding: 8px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  z-index: -100;
  margin-bottom: 16px;
  .el-button {
    width: 164px;
    margin-left: 0;
    & + .el-button {
      margin-top: 8px;
    }
  }
  opacity: 0;
}
.action-buttons-wrapper-active {
  opacity: 1;
  z-index: 100;
}
</style>
