<template>
  <div :class="{ 'menu-wrapper-active': open }" class="menu-wrapper">
    <ul :class="{ 'client-profile-menu-active': open }" class="client-profile-menu">
      <li
        :class="{ active: active === 'history' }"
        @click="pushScreen('Perfil - Histórico de lançamentos')"
      >Histórico de lançamentos</li>
      <li
        :class="{ active: active === 'info' }"
        @click="pushScreen('Perfil - Dados Pessoais')"
      >Dados Pessoais</li>
      <li
        :class="{ active: active === 'creditHistory' }"
        @click="pushScreen('Perfil - Histórico de consultas')"
      >Histórico de consultas (CPF)</li>
      <li
        :class="{ active: active === 'statements' }"
        @click="pushScreen('Perfil - Faturas')"
      >Faturas</li>
      <!-- <li
        :class="{ active: active === 'installments' }"
        @click="pushScreen('Perfil - Parcelas')"
      >Parcelas</li> -->
      <li
        :class="{ active: active === 'dueStatements' }"
        @click="pushScreen('Perfil - Compras a vencer')"
      >Compras a vencer</li>
      <li
        :class="{ active: active === 'notifications' }"
        @click="pushScreen('Perfil - Notificações')"
      >Notificações</li>
      <li
        :class="{ active: active === 'charges' }"
        @click="pushScreen('Perfil - Cobranças')"
      >Cobrança</li>
      <li
        :class="{ active: active === 'loose-billets' }"
        @click="pushScreen('Perfil - Boletos avulsos')"
      >Boletos avulsos</li>
      <li
        :class="{ active: active === 'settings' }"
        @click="pushScreen('Perfil - Configurações')"
      >Configurações</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    active: {
      type: String,
      default: ""
    }
  },
  methods: {
    pushScreen(name) {
      this.$emit("onPress");
      this.$router.push({ name });
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -10;
  }
}
.menu-wrapper-active {
  transition: all 0.4s ease-in-out;
  @media screen and(max-width: 768px) {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
}
.client-profile-menu {
  position: sticky;
  top: 32px;
  list-style: none;
  li {
    padding: 16px;
    border-radius: 40px;
    font-family: Montserrat;
    font-size: 0.85rem;
    transition: 0.3s all ease-in-out;
    color: rgba(108, 115, 124, 1);
    & + li {
      margin-top: 16px;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba(108, 115, 124, 0.1);
    }

    &:active {
      background-color: rgba(108, 115, 124, 0.4);
      box-shadow: 2px 2px 4px rgba(108, 115, 124, 0.3);
      font-weight: 700;
    }
  }
  .active {
    background-color: rgba(108, 115, 124, 0.1);
  }

  @media screen and (max-width: 768px) {
    width: 250px;
    padding: 16px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 2px 4px rgba(0, 92, 103, 0.2);
    transform: translateX(-350px);
    transition: all 0.4s ease-in-out;
  }
}

.client-profile-menu-active {
  transform: translateX(0);
}
</style>
