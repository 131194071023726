<template>
  <img
    :src="url || $avatar"
    class="client-avatar"
    alt="selfie do cliente"
  >
</template>

<script>
export default {
  props: {
    url: {
      type: [String, null],
      default: null,
    }
  }
}
</script>

<style lang="scss" scoped>
  .client-avatar {
    width: 190px;
    height: 190px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
  }
</style>
