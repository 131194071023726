<template>
  <section class="client-profile-container">
    <client-profile-header :loading="loading" :client="client" :score-neopag="scoreNeopag" />
    <div v-if="!loading" class="actions-buttons-wrapper">
      <el-button
        v-if="activeStore && activeStore.permissions && activeStore.permissions.sales"
        :disabled="cantSell"
        type="success"
        size="large"
        round
        @click="handleNavigation('Detalhes da venda', true)"
      >Vender</el-button>
      <el-button
        v-if="activeStore && activeStore.permissions && activeStore.permissions.payments"
        type="primary"
        size="large"
        round
        @click="handleNavigation('Detalhes do recebimento', true)"
      >Receber</el-button>
      <el-button
        v-if="activeStore && activeStore.permissions && activeStore.permissions.deals && authOrigin !== 'app'"
        :disabled="!hasStatements"
        type="secondary"
        size="large"
        style="background-color: #ffd16e"
        round
        @click="handleNavigation('Detalhes do reparcelamento', true)"
      >Reparcelar</el-button>
    </div>
    <div class="wrapper-kpi">
      <client-profile-kpis :client="client" :loading="loading" />
    </div>
    <div class="separator" />
    <el-button size="small" round class="button-menu" @click="open = !open">
      Menu
      <i
        :class="{
          'el-icon-arrow-right': !open,
          'el-icon-arrow-left': open,
        }"
      />
    </el-button>
    <div class="wrapper">
      <client-profile-menu
        :active="$route.meta.params.activeMenu"
        :open="open"
        @onPress="open = !open"
      />
      <router-view />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import * as types from "@/store/types";

import ClientProfileHeader from "@/components/ClientProfile/ClientProfileHeader";
import ClientProfileMenu from "@/components/ClientProfile/ClientProfileMenu";
import ClientProfileKpis from "@/components/ClientProfile/ClientProfileKpis";

export default {
  components: {
    ClientProfileHeader,
    ClientProfileMenu,
    ClientProfileKpis
  },
  data() {
    return {
      loading: false,
      open: false
    };
  },
  computed: {
    ...mapGetters({
      activeStore: types.AUTH_ACTIVE_STORE_DETAILS,
      authOrigin: types.AUTH_ORIGIN,
      client: types.CLIENT_DETAILS,
      scoreNeopag: types.CLIENT_SCORE_NEOPAG,
      storeInfo: types.STORE_INFO,
      storeID: types.AUTH_ACTIVE_STORE
    }),
    cantSell() {
      if (!this.client.store_status) return true;

      const statusID = this.client.store_status.id;

      if (this.storeInfo.legacy_mode) {
        return [1, 5, 10, 11].indexOf(statusID) < 0;
      }

      return statusID !== 1;
    },
    hasStatements() {
      const statements = this.client?.user_statements;

      if (!statements) {
        return false;
      }

      const closed =
        (statements?.closed_statement &&
          +statements.closed_statement.total_remaining_amount) ||
        0;
      const open =
        (statements?.open_statement &&
          +statements.open_statement.total_remaining_amount) ||
        0;
      const future =
        (statements?.future_statement &&
          +statements.future_statement.total_remaining_amount) ||
        0;

      return Math.abs(closed) + Math.abs(open) + Math.abs(future) > 0;
    }
  },
  async mounted() {
    this.loading = true;
    const { clientID } = this.$route.params;
    try {
      await this.$store.dispatch(types.CLIENT, clientID);
      await this.$store.dispatch(types.CLIENT_SCORE_NEOPAG, {
        userID: clientID,
        useBureau: false,
        storeId: this.storeID
      });
    } catch (error) {
      this.isSubmitting = false;
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  },
  methods: {
    handleNavigation(name, isClient) {
      try {
        if (this.authOrigin === "app") {
          switch (name) {
            case "Detalhes da venda": {
              return NativeAppChannel.postMessage(`sale/${this.client.cpf}`);
            }
            case "Detalhes do recebimento": {
              return NativeAppChannel.postMessage(`payment/${this.client.cpf}`);
            }
          }
        }
        this.$router.push({ name, params: { isClient } });
      } catch (error) {
        window.alert(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.client-profile-container {
  padding: 32px;
  .separator {
    width: 100%;
    height: 1px;
    background-color: #6c737c;
    opacity: 0.3;
    margin: 32px 0;
  }
  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-column-gap: 16px;
    @media screen and (max-width: 768px) {
      display: block;
      position: relative;
    }
  }
  .wrapper-kpi {
    margin-top: 32px;
  }
  .button-menu {
    display: none;
    @media screen and (max-width: 768px) {
      margin-bottom: 8px;
      display: block;
    }
  }
  .actions-buttons-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    .el-button {
      width: 120px;
      font-weight: 700;
    }
    @media screen and (max-width: 425px) {
      flex-flow: column wrap;
      .el-button {
        margin: 0;
        width: 90%;
        & + .el-button {
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
