<template>
  <section>
    <div v-show="loading">
      <square-skeleton
        :count="7"
        :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px',
      }"
        style="width: 100%"
      />
    </div>
    <div v-show="!loading">
      <el-dialog :visible.sync="showConfirmationDialog" :fullscreen="$isMobile">
        <div style="text-align: center">
          <h3>{{whatDo === 'desactive' ? 'Tem certeza que deseja desativar esse cliente?' : 'Tem certeza que deseja ativar esse cliente?' }}</h3>
          <br />
          <el-button :loading="loading" type="danger" @click="updateClientStatus(whatDo)">Confirmar</el-button>
          <el-button type="secondary" @click="showConfirmationDialog = false">Cancelar</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="showReativationDialog" :fullscreen="$isMobile" width="320px">
        <div class="client-reativation">
          <h3>Alterar Limite</h3>
          <radial-progress
            :diameter="200"
            :stroke-width="11"
            :completed-steps="userPercentageScore"
            :total-steps="100"
            :start-color="userPercentageColor"
            :stop-color="userPercentageColor"
            inner-stroke-color="rgba(108, 115, 124, 0.3)"
          >
            <!-- <ClientAvatar :url="client.selfie" /> -->
            <p class="score-label mini">Score: {{ scoreNeopag.neopag_score }}</p>
            <p class="score-label">
              Limite sugerido:
              <br />
              <span :style="{color: userPercentageColor }" class="money">R$</span>
              <span :style="{color: userPercentageColor }">{{ suggestedCredit }}</span>
            </p>
          </radial-progress>
          <div class="client-reativation__input">
            <span class="label">Novo limite</span>
            <div class="el-input el-input--mini">
              <money :disabled="loading" v-bind="$money" v-model="limit" class="el-input__inner" />
            </div>
          </div>
          <div class="client-reativation__actions">
            <el-button
              :loading="loading"
              :disabled="limit <= 0"
              type="success"
              @click="handleAprove"
            >Confirmar</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="showDueDayDialog" :fullscreen="$isMobile" class="change-due-date">
        <el-form
          :rules="changeDueDayFormRules"
          :model="changeDueDayForm"
          @submit.prevent.native="e => e.preventDefault()"
        >
          <el-form-item prop="dueDay" required label="Novo dia de vencimento">
            <el-select v-model="changeDueDayForm.dueDay" placeholder="Selecione o dia">
              <el-option v-for="item in dueDays" :key="item" :label="item" :value="item" />
            </el-select>
          </el-form-item>
          <el-form-item v-show="changeDueDayForm.retroactive === false" label="Retroativa?">
            <el-checkbox v-model="changeDueDayForm.retroactive">
              Também quero realizar a mudança
              <b>reatroativa</b>
            </el-checkbox>
          </el-form-item>
          <p v-if="!changeDueDayForm.retroactive">
            Ao confirmar, iremos mudar o vencimento das faturas
            <br />
            <b>criadas a partir da data de hoje ({{ $d(new Date(), 'sale_summary') }})</b>.
          </p>
          <p v-else>
            Ao confirmar, iremos mudar o vencimento de
            <br />
            <b>todas as faturas em vigor deste cliente</b>.
          </p>
          <el-form-item>
            <el-button :disabled="loading" type="danger" @click="handleChangeDueDay">Confirmar</el-button>
            <el-button
              :disabled="loading"
              type="secondary"
              @click="showDueDayDialog = false"
            >Cancelar</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <el-dialog
        :visible.sync="showDocumentDialog"
        :fullscreen="$isMobile"
        @close="showDocumentDialog = false;"
      >
        <div slot="title">
          <h2 class="dialog-title">Envio de documento</h2>
        </div>
        <documents-proof :show-document-dialog="showDocumentDialog" />
      </el-dialog>
      <el-dialog
        :visible.sync="showAddressProofDialog"
        :fullscreen="$isMobile"
        @close="showAddressProofDialog = false"
      >
        <div slot="title">
          <h2 class="dialog-title">Envio do comprovante de endereço</h2>
        </div>
        <address-proof :show-address-proof-dialog="showAddressProofDialog" />
      </el-dialog>
      <el-dialog
        :visible.sync="showIncomeProofDialog"
        :fullscreen="$isMobile"
        @close="showIncomeProofDialog = false"
      >
        <div slot="title">
          <h2 class="dialog-title">Envio do comprovante de renda</h2>
        </div>
        <income-proof :show-income-proof-dialog="showIncomeProofDialog" />
      </el-dialog>
      <div class="switch-container">
        <div class="switch-wrapper">
          <h3>Boleto</h3>
          <el-switch
            v-if="!client.is_neopag_credit || this.authUser.superuser"
            :disabled="!subscription.allow_billets || !subscription.iugu_activated || client.is_neopag_credit"
            v-model="billet"
            style="display: block"
            active-color="#56C779"
            inactive-color="#f1565d"
            active-text="Ativo"
            inactive-text="Inativo"
            @change="updateBillet"
          />
        </div>
        <div class="switch-wrapper">
          <h3>Cobrança</h3>
          <el-switch
            v-if="!client.is_neopag_credit || this.authUser.superuser"
            v-model="sendChargingNotifications"
            style="display: block"
            active-color="#56C779"
            inactive-color="#f1565d"
            active-text="Ativa"
            inactive-text="Inativa"
            @change="updateChargingNotifications"
          />
        </div>
      </div>
      <div class="buttons-wrapper">
        <el-button
          :disabled="false"
          class="settings-button"
          round
          size="small"
          type="primary"
          @click="showDueDayDialog = true"
        >Alterar dia do vencimento</el-button>
        <el-button
          v-if="!client.is_neopag_credit || this.authUser.superuser"
          :disabled="auth_details && auth_details.permissions && auth_details.permissions.change_total_limit ? false : true"
          class="settings-button"
          round
          size="small"
          type="primary"
          @click="showReativationDialog = true"
        >Alterar limite</el-button>
        <el-button
          v-if="this.authUser.superuser"
          class="settings-button"
          round
          size="small"
          type="primary"
          @click="showReativationDialog = true"
        >Alterar limite (Administrador)</el-button>
        <el-button
          v-if="!client.is_neopag_credit || this.authUser.superuser"
          class="settings-button"
          round
          size="small"
          type="info"
          @click="showConfirmationDialog = true; whatDo = 'desactive'"
        >Desativar cliente</el-button>
        <el-button
          v-if="!client.is_neopag_credit || this.authUser.superuser"
          class="settings-button"
          round
          size="small"
          type="info"
          @click="showConfirmationDialog = true; whatDo = 'active'"
        >Aprovar cliente</el-button>
        <el-button
          class="settings-button"
          round
          size="small"
          type="success"
          @click="showDocumentDialog = true;"
        >Documento</el-button>
        <el-button
          class="settings-button"
          round
          size="small"
          type="success"
          @click="showAddressProofDialog = true;"
        >Comprovante de endereço</el-button>
        <el-button
          class="settings-button"
          round
          size="small"
          type="success"
          @click="showIncomeProofDialog = true;"
        >Comprovante de renda</el-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
import * as types from "@/store/types";
import toMoney from "@/helpers/toMoney";
import RadialProgress from "vue-radial-progress";
import DocumentsProof from "@/containers/UploadFiles/DocumentsProof";
import AddressProof from "@/containers/UploadFiles/AddressProof";
import IncomeProof from "@/containers/UploadFiles/IncomeProof";


export default {
  components: {
    RadialProgress,
    Money,
    DocumentsProof,
    AddressProof,
    IncomeProof
  },
  data() {
    const dueDayValidation = (rule, value, cb) => {
      if (!value) {
        return cb(new Error("Campo obrigatório"));
      }
      if (value < 1 || value > 28) {
        return cb(new Error("Digite um número entre 1 e 28"));
      }
      return cb();
    };
    return {
      is_neopag_credit_config: false,
      showConfirmationDialog: false,
      whatDo: "",
      showReativationDialog: false,
      showDueDayDialog: false,
      showDocumentDialog: false,
      showAddressProofDialog: false,
      showIncomeProofDialog: false,
      loading: false,
      billet: false,
      sendChargingNotifications: false,
      limit: 0,
      changeDueDayForm: {
        dueDay: 10,
        retroactive: true
      },
      changeDueDayFormRules: {
        dueDay: [{ validator: dueDayValidation, trigger: "blur" }]
      }
    };
  },
  computed: {
    ...mapGetters({
      authUser: types.AUTH_USER,
      subscription: types.SUBSCRIPTION_CONFIG,
      client: types.CLIENT_DETAILS,
      scoreNeopag: types.CLIENT_SCORE_NEOPAG,
      storeConfig: types.STORE_CONFIG,
      storeDetails: types.CLIENT_STORE_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS
    }),
    dueDays() {
      if (this.storeConfig && this.storeConfig.due_days.length > 0) {
        return this.storeConfig.due_days;
      }
      return [...Array(28).keys()].map(it => ++it);
    },
    userPercentageScore() {
      const { neopag_score } = this.scoreNeopag;
      if (this.scoreNeopag && neopag_score) {
        const score = (neopag_score * 100) / 60000; // percentage score where 60.000 is equal to 100%;
        return score;
      }
      return 0;
    },
    userPercentageColor() {
      const { level } = this.scoreNeopag;
      if (this.scoreNeopag && level) {
        if (level <= 2) {
          return "rgba(151, 4, 12, 0.7)";
        } else if (level > 2 && level <= 5) {
          return "rgba(253, 152, 39, 0.7)";
        } else {
          return "rgba(108, 167, 84, 0.7)";
        }
      }
      return "rgba(151, 4, 12, 0.7)";
    },
    suggestedCredit() {
      if (this.scoreNeopag && this.scoreNeopag.recommended_credit) {
        const amount = toMoney(this.scoreNeopag.recommended_credit);
        return amount.replace("R$", "").replace(" ", "");
      }
      return "0,00";
    }
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.CLIENT, this.$route.params.clientID);
      await this.$store.dispatch(types.CLIENT_STORE_DETAILS, {
        id: this.client.store_customer_id,
        storeID: this.storeID
      });
      this.billet = this.storeDetails.create_billets;
      this.sendChargingNotifications = this.storeDetails.send_charging_notifications;
      this.loading = false;
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);

      this.is_neopag_credit_config = this.storeConfig.is_neopag_credit_config;

    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.limit = this.scoreNeopag.recommended_credit;

    console.trace(this.client);

  },
  methods: {
    async updateBillet(value) {
      if (this.$refs.input) {
        this.$refs.input.che;
      }
      this.loading = true;
      await this.$store
        .dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.client.store_customer_id,
          storePayload: {
            store_customer: {
              create_billets: value
            }
          }
        })
        .catch(error => {
          this.loading = false;
          if (this.$raven) {
            this.$raven.captureException(error.message, {
              req: JSON.stringify(error)
            });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success({
        title: "Sucesso",
        message: "Emissão de boleto atualizada"
      });
      this.loading = false;
    },
    async updateChargingNotifications(value) {
      this.loading = true;
      await this.$store
        .dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.client.store_customer_id,
          storePayload: {
            store_customer: {
              send_charging_notifications: value
            }
          }
        })
        .catch(error => {
          this.loading = false;
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.$notify.success({
        title: "Cobrança atualizada."
      });
      this.loading = false;
    },
    async updateClientStatus(whatDo) {
      try {
        this.loading = true;
        await this.$store.dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.client.store_customer_id,
          storePayload: {
            store_customer: {
              status_id: whatDo === "desactive" ? 8 : 5
            }
          }
        });
        await this.$store.dispatch(types.CLIENT, this.$route.params.clientID);
        this.$notify.success({
          title:
            whatDo === "desactive"
              ? "Cliente desativado com sucesso!"
              : "Cliente ativado com sucesso!"
        });
      } catch (error) {
        this.loading = false;
        this.showConfirmationDialog = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.showConfirmationDialog = false;
      this.loading = false;
    },
    async handleAprove() {
      this.loading = true;
      await this.$store.dispatch(types.CLIENT_UPDATE_STORE, {
        id: this.client.store_customer_id,
        storePayload: {
          store_customer: {
            total_credit: this.limit
          }
        }
      });
      await this.$store
        .dispatch(types.CLIENT, this.$route.params.clientID)
        .catch(error => {
          this.loading = false;
          this.showReativationDialog = false;
          if (this.$raven && error.status === 500) {
            this.$raven.captureException(error.message, { req: error });
          }
          this.$notify.error({ title: error.message });
        });
      this.loading = false;
      this.showReativationDialog = false;
      this.$notify.success({
        title: "Situação do cliente atualizada!"
      });
    },
    async handleChangeDueDay() {
      try {
        this.loading = true;
        await this.$store.dispatch(types.CLIENT_CHANGE_DUE_DAY, {
          storeCustomerID: this.client.store_customer_id,
          payload: {
            due_day: +this.changeDueDayForm.dueDay,
            retroactive: true
          }
        });
        await this.$store.dispatch(types.CLIENT, this.$route.params.clientID);
        this.showDueDayDialog = false;
        this.loading = false;
      } catch (error) {
        this.showDueDayDialog = false;
        this.loading = false;
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-title {
  color: $laranja;
  font-weight: bold;
  text-align: center;
}
.settings-button {
  display: block;
  width: 200px;
  margin: 1rem 0 0 0;
  @media screen and (max-width: 767px) {
    & + .settings-button {
      margin-top: 16px;
    }
  }
}
.switch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.switch-wrapper {
  text-align: center;
  max-width: 200px;
  margin-bottom: 16px;
}
.change-due-date {
  text-align: center;
  font-family: Montserrat;
  font-weight: 500;
  p {
    margin-bottom: 1rem;
    font-size: 14px;
  }
  small {
    font-weight: 400;
    position: relative;
    top: -8px;
    font-size: 12px;
    font-style: italic;
  }
}
.client-reativation {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
  h3 {
    margin-bottom: 16px;
  }
  .el-button {
    margin-top: 16px;
  }
  &__input {
    margin-top: 16px;
  }
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.score-label {
  align-self: center;
  font-family: "Montserrat";
  letter-spacing: 1.125px;
  color: $preto;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  span {
    align-self: center;
    font-size: 22px;
    font-weight: 700;
    color: rgba(108, 167, 84, 0.5);
  }
  .money {
    font-size: 11px;
  }
}
.mini {
  font-size: 11px;
  margin: 0 0 8px;
}
</style>
