<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column.key ? column.key : Math.random() * 100"
            :style="{
              textAlign: column.align ? column.align : 'center'
            }"
            class="table-head"
          >
            <span class="table-span__head">{{ column.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id" class="table-row">
          <td
            v-for="column in columns"
            :key="column.key ? column.key : Math.random() * 100"
            :style="{
              textAlign: column.align ? column.align : 'center',
              width: column.width ? column.width : 'auto',
              minWidth: column.width ? column.width : '140px',
              maxWidth: column.maxWidth ? column.maxWidth : '140px',
              opacity: column.opacity ? column.opacity : 1
            }"
            class="table-data"
          >
            <div v-if="column.key === 'actions'">
              <action-button
                :disabled="column.disabled ? column.disabled(item) : false"
                :options="column.options"
                :item="item"
                :off-set="column.offSet"
              />
            </div>
            <span
              v-else-if="column.key && column.render"
              class="table-span"
              v-html="column.render(item[column.key])"
            />
            <span
              v-else-if="!column.key && column.render"
              class="table-span"
              v-html="column.render(item)"
            />
            <span v-else-if="column.key && !column.render" class="table-span">
              {{ item[column.key] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mobile-wrapper">
      <el-card v-for="item in data" :key="item.id">
        <section class="mobile-content">
          <div
            v-for="column in columns"
            :key="column.key ? column.key : Math.random() * 100"
            class="mobile-item"
          >
            <span class="mobile-label">
              {{ column.label }}
            </span>
            <div v-if="column.key === 'actions'">
              <action-button
                :disabled="column.disabled ? column.disabled(item) : false"
                :options="column.options"
                :item="item"
                :off-set="column.offSet"
                :mobile="true"
              />
            </div>
            <span
              v-else-if="column.key && column.render"
              class="mobile-span"
              v-html="column.render(item[column.key])"
            />
            <div
              v-else-if="!column.key && column.render"
              class="mobile-span"
              v-html="column.render(item)"
            />
            <span v-else-if="column.key && !column.render" class="mobile-span">
              {{ item[column.key] }}
            </span>
          </div>
        </section>
      </el-card>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton";

export default {
  components: {
    ActionButton
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 16px;
  table {
    padding-bottom: 16px;
  }
  @media screen and (max-width: 425px) {
    table {
      display: none;
    }
  }
}
.mobile-wrapper {
  display: none;
  @media screen and (max-width: 425px) {
    display: block;
    .el-card + .el-card {
      margin-top: 16px;
    }
  }
}
.mobile-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 8px;
}
.mobile-label {
  font-weight: 700;
  display: block;
  font-family: Montserrat;
  color: #6c737c;
}
.mobile-span {
  display: block;
  font-family: Montserrat;
  color: #6c737c;
  font-size: 18px;
}
.table-head {
  padding: 8px 0;
  border-bottom: solid 1px rgba(108, 115, 124, 0.3);
}
.table-data {
  padding: 8px 0;
  border-bottom: solid 1px rgba(108, 115, 124, 0.3);
}
.table-span {
  font-family: Montserrat;
  color: #6c737c;
  text-align: left;
  font-size: 14px;
  &__head {
    font-weight: 700;
  }
}
.table-row {
  transition: 0.1s all ease-in-out;
  &:hover {
    background-color: rgba(108, 115, 124, 0.3);
  }
}
</style>
