<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px'
      }"
      style="width: 100%"
    />
  </skeleton-loading>
  <section v-else>
    <table-component :columns="columns" :data="creditHistory" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";
import TableComponent from "@/components/Table";
import toMoney from "@/helpers/toMoney";

export default {
  components: {
    TableComponent
  },
  filters: {
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : "")
  },
  data() {
    return {
      loading: true,
      redoing: false,
      redoDueDate: "",
      columns: [
        {
          key: "created_at",
          label: "Data",
          render: created_at =>
            created_at ? moment(created_at).format("DD/MM/YYYY") : ""
        },
        {
          key: "neopag_score",
          label: "Score",
          render: neopag_score => (neopag_score ? neopag_score : "")
        },
        {
          key: "recommended_credit",
          label: "Crédito recomendado",
          render: recommended_credit =>
            `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(
              recommended_credit
            )}<span>`
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      creditHistory: types.USER_CREDIT_HISTORY,
      client: types.CLIENT_DETAILS
    })
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch(types.USER_CREDIT_HISTORY, {
        userID: this.client.id
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.collapse-header {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  align-items: center;
  padding-right: 14px;
  h2 {
    font-family: Montserrat;
    letter-spacing: 1.025px;
    font-weight: 700;
  }
  .wrapper {
    display: inline-block;
    height: 48px;
    margin: 6px 0 0 12px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      text-align: center;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}

</style>
