<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="7"
      :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px',
      }"
      style="width: 100%"
    />
    <square-skeleton
      :count="1"
      :box-properties="{
        bottom: '15px',
        height: '300px',
        top: '20px',
      }"
      style="width: 100%"
    />
  </skeleton-loading>
  <section v-else-if="client">
    <div class="info-grid">
      <div>
        <p>
          <b>Nome:</b>
          {{ client.name }}
        </p>
        <p>
          <b>Data de nascimento:</b>
          {{ client.birthdate | formatDate }}
        </p>
        <p>
          <b>CPF:</b>
          {{ client.cpf | formatCPF }}
        </p>
        <p>
          <b>RG:</b>
          {{ client.rg }}
        </p>
      </div>
      <div>
        <p>
          <b>Celular:</b>
          <span
            :class="[client.phone_confirmed ? 'isConfirmed' : 'isntConfirmed' ]"
          >{{ client.phone | formatPhone }}</span>
          <svgicon
            v-if="!client.phone_confirmed"
            name="cell"
            @click="showConfirmNumberDialog = true"
          />
        </p>
        <p>
          <b>E-mail:</b>
          {{ client.email }}
        </p>
        <p>
          <b>Cliente desde:</b>
          {{formatDate(clientStoreDetails.queried_at)}}
        </p>
        <div v-if="client && client.parents && client.parents.length > 0">
          <p>
            <b>Filiação:</b>
          </p>
          <p v-for="parent in client.parents" :key="parent">{{ parent }}</p>
        </div>
      </div>
    </div>
    <div class="info-grid">
      <div>
        <p>
          <b>Endereço:</b>
          {{ client.address ? client.address.street : '' }} - {{ client.address ? client.address.number : null }}
        </p>
        <p>
          <b>Bairro:</b>
          {{ client.address ? client.address.neighbourhood : '' }}
        </p>
        <p>
          <b>Cidade:</b>
          {{ client.address ? client.address.city : '' }}
        </p>
      </div>
      <div>
        <p>
          <b>Complemento:</b>
          {{ client.address ? client.address.complement : '' }}
        </p>
        <p>
          <b>CEP:</b>
          {{ client.address ? client.address.cep : null }}
        </p>
        <p>
          <b>UF:</b>
          {{ client.address ? client.address.uf : '' }}
        </p>
      </div>
    </div>
    <div class="observation-grid">
      <el-button type="succes" size="mini" @click="editObservation = true">Observações:</el-button>
      <p class="observation-box">{{ client.description ? client.description : '' }}</p>
    </div>
    <div class="btn-grid">
      <el-button round @click="secundaryPhoneDialog = true">Ver lista de telefones</el-button>
      <!--[kenzo][13-08-2021] possibilitar loja alterar cadastro dos clientes fixo FALSE :disabled="client.registered_in_app"  -->
      <el-button
        :disabled="false"
        type="success"
        round
        @click="editInfoDialog = true"
      >Atualizar informações</el-button>
      <el-button type="success" round @click="editPhoneDialog = true">Atualizar telefone principal</el-button>
      <el-button
        type="success"
        round
        @click="editSecundaryPhoneDialog = true"
      >Cadastrar telefone adicional</el-button>
    </div>
    <el-dialog :visible.sync="editInfoDialog" width="60%">
      <div class="tip">
        <p>Preencha somente os campos que deseja atualizar</p>
      </div>
      <h2>Atualizar informações do cliente</h2>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input v-model="form.name" :placeholder="client.name" size="mini">
            <template slot="prepend">Nome:</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.email" :placeholder="client.email" size="mini">
            <template slot="prepend">Email:</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-mask="'XXXXXXXXXXXXXXXXXXXX'"
            v-model="form.rg"
            placeholder="123456789"
            size="mini"
          >
            <template slot="prepend">RG:</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-mask="'##-##-####'"
            v-model="form.birthdate"
            placeholder="DD-MM-AAAA"
            size="mini"
          >
            <template slot="prepend">Data de nascimento:</template>
          </el-input>
        </el-form-item>
        <el-switch
          v-model="locationInformation"
          active-text="Atualizar endereço"
          inactive-text="Não atualizar endereço"
        />
        <div v-if="locationInformation">
          <el-form-item>
            <el-input v-mask="'#####-###'" v-model="form.cep" placeholder="12345-678" size="mini">
              <template slot="prepend">CEP:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.street"
              :placeholder="client.address ? client.address.street : ''"
              size="mini"
            >
              <template slot="prepend">Logradouro:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.number"
              :placeholder="client.address ? client.address.number : null"
              size="mini"
            >
              <template slot="prepend">Número:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.neighbourhood"
              :placeholder="client.address ? client.address.neighbourhood : ''"
              size="mini"
            >
              <template slot="prepend">Bairro:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.city"
              :placeholder="client.address ? client.address.city : ''"
              size="mini"
            >
              <template slot="prepend">Cidade:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.membership" :placeholder="client.mebership" size="mini">
              <template slot="prepend">Filial:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.complement"
              :placeholder="client.address ? client.address.complement : ''"
              size="mini"
            >
              <template slot="prepend">Complemento:</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-mask="'XX'"
              v-model="form.uf"
              :placeholder="client.address ? client.address.uf : ''"
              size="mini"
            >
              <template slot="prepend">UF:</template>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="success" @click="validateUserInfo">Atualizar</el-button>
          <el-button type="danger" @click="closeEditInfo">Cancelar</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="editPhoneDialog" width="60%">
      <div class="tip">
        <p>Caso deseje alterar o número do cliente, será necessário confirmar o novo número via sms</p>
      </div>
      <h2>Atualizar telefone do cliente</h2>
      <el-form v-mask="'## #####-####'" ref="phoneForm" :model="phoneForm">
        <el-form-item>
          <el-input
            v-model="phoneForm.phone"
            :placeholder=" client.phone ? client.phone.replace('+55','') : '00 00000-0000'"
          >
            <template slot="prepend">Telefone</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="phoneIsEqual"
            type="success"
            @click="validateUserInfoPhone"
          >Atualizar</el-button>
          <el-button type="danger" @click="editPhoneDialog = false">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="editSecundaryPhoneDialog" width="60%">
      <!-- <div class="tip">
        <p>Caso deseje alterar o número do cliente, será necessário confirmar o novo número via sms</p>
      </div>-->
      <h2>Cadastrar telefone secundário</h2>
      <el-form v-mask="['(##)#####-####', '(##)####-####']" ref="phoneForm" :model="phoneForm">
        <el-form-item>
          <el-input v-model="phoneSecundaryForm.phone" :placeholder="'(00)00000-0000'">
            <template slot="prepend">Telefone</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            type="textarea"
            placeholder="Descrição"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="phoneSecundaryForm.description"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="handleSecundaryPhoneUpdate">Cadastrar</el-button>
          <el-button type="danger" @click="editSecundaryPhoneDialog = false">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="showConfirmNumberDialog" width="60%">
      <h2>Validar número de telefone</h2>
      <div class="tip">
        <p>
          Um código de confirmação será enviado para o número:
          <b>{{ client.phone }}</b>
        </p>
        <br />
        <el-button type="warning" @click="generateToken">Gerar token</el-button>
      </div>
      <el-form>
        <el-form-item>
          <el-form-item>
            <el-input
              v-mask="'######'"
              v-if="tokenIsGenerated"
              v-model="tokenCode"
              class="token-input"
            >
              <template slot="prepend" class="token-input">Token:</template>
            </el-input>
          </el-form-item>
          <el-button
            :disabled="!tokenIsGenerated"
            type="success"
            @click="handlePhoneValidation(); validateUserInfo = false"
          >Validar</el-button>
          <el-button
            type="danger"
            @click="showConfirmNumberDialog = false; tokenIsGenerated = false"
          >Cancelar</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="editObservation" width="60%">
      <h2>Editar observações</h2>
      <el-form>
        <el-form-item>
          <el-form-item>
            <el-input v-model="observationText" type="textarea">
              <template slot="prepend" class="token-input">Observações:</template>
            </el-input>
          </el-form-item>
          <div style="margin-top: 1rem;">
            <el-button
              type="success"
              @click="sendObservationText(); editObservation = false"
            >Atualizar</el-button>
            <el-button type="danger" @click="editObservation = false">Cancelar</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="secundaryPhoneDialog" :fullscreen="$isMobile">
      <table-component :columns="columns" :data="client.phones" />
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";
import toCpf from "@/helpers/toCPF";
import { format } from "@/helpers/phone";
import phoneHelper from "@/helpers/phone";
import cep from "cep-promise";
import _ from "lodash";
import TableComponent from "@/components/Table";

export default {
  components: {
    TableComponent
  },
  filters: {
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    formatCPF: cpf => (cpf ? toCpf(cpf) : ""),
    formatPhone: phone => (phone ? format(phone.replace("+55", "")) : "")
  },
  data() {
    return {
      loading: true,
      editInfoDialog: false,
      editPhoneDialog: false,
      editSecundaryPhoneDialog: false,
      secundaryPhoneDialog: false,
      editObservation: false,
      showConfirmNumberDialog: false,
      tokenIsGenerated: false,
      tokenCode: null,
      phoneIsEqual: false,
      observationText: "",
      form: {
        name: "",
        birthdate: "",
        rg: "",
        email: "",
        membership: "",
        street: "",
        number: null,
        neighbourhood: "",
        city: "",
        complement: "",
        cep: "",
        uf: ""
      },
      phoneForm: {
        phone: ""
      },
      phoneSecundaryForm: {
        phone: "",
        description: ""
      },
      locationInformation: false,
      columns: [
        {
          label: "Adicionado em",
          key: "created_at",
          render: created_at =>
            `<span>${moment(created_at).format("DD/MM/YYYY")}</span>`
        },
        {
          label: "Telefone",
          key: "phone",
          render: phone => `<span>${phone.replace(/[+55()-\s]/g, "")}</span>`
        },
        {
          label: "Descrição",
          key: "description",
          render: description => `<span>${description}</span>`
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      clientStoreDetails: types.CLIENT_STORE_DETAILS
    })
  },
  watch: {
    "form.cep": function(val) {
      val.length < 9 ? "" : this.cepGetAddressInformation(val);
    }
  },
  async mounted() {
    try {
      const { clientID } = this.$route.params;
      await this.$store.dispatch(types.CLIENT, clientID);
      await this.$store.dispatch(types.CLIENT_STORE_DETAILS, {
        id: this.client.store_customer_id,
        storeID: this.storeID
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  },
  methods: {
    cepGetAddressInformation: _.debounce(function(userCep) {
      userCep.replace("-", "");
      cep(userCep)
        .then(this.autoFillAddress)
        .catch(err => {
          this.$notify.error({ title: err.message });
        });
    }, 500),
    autoFillAddress(value) {
      this.form.street = value.street;
      this.form.neighbourhood = value.neighbourhood;
      this.form.city = value.city;
      this.form.uf = value.state;
    },
    validateUserInfo() {
      const payload = {};
      payload.id = this.client.id;
      payload.name = this.form.name ? this.form.name : this.client.name;
      payload.birthdate = this.form.birthdate
        ? moment(this.form.birthdate, "DD-MM-YYYY").format("YYYY-MM-DD")
        : this.client.birthdate;
      payload.rg = this.form.rg ? this.form.rg : this.client.rg;
      payload.email = this.form.email ? this.form.email : this.client.email;

      if (this.locationInformation) {
        payload.street = this.form.street
          ? this.form.street
          : this.client.address
          ? this.client.address.street
          : "";
        payload.number = this.form.number
          ? this.form.number
          : this.client.address
          ? this.client.address.number
          : "";
        payload.neighbourhood = this.form.neighbourhood
          ? this.form.neighbourhood
          : this.client.address
          ? this.client.address.neighbourhood
          : "";
        payload.city = this.form.city
          ? this.form.city
          : this.client.address
          ? this.client.address.city
          : "";
        payload.complement = this.form.complement
          ? this.form.complement
          : this.client.address
          ? this.client.address.complement
          : "";
        payload.membership = this.form.membership
          ? this.form.membership
          : this.client.membership;
        payload.cep = this.form.cep
          ? this.form.cep
          : this.client.address
          ? this.client.address.cep
          : "";
        payload.uf = this.form.uf
          ? this.form.uf
          : this.client.address
          ? this.client.address.uf
          : "";
      }
      this.sendInfoData(payload);
    },
    validateUserInfoPhone() {
      const payload = {};
      payload.phone = this.phoneForm.phone
        ? `+55${phoneHelper.unformat(this.phoneForm.phone)}`
        : this.client.phone;
      this.checkNumberIsEqual(payload);
    },
    checkNumberIsEqual(payload) {
      if (payload.phone === this.client.phone) {
        this.$notify.error({
          title:
            "O número é igual ao número cadastrado, por favor insira outro número."
        });
        this.phoneIsEqual = true;
        this.phoneForm.phone = "";
        this.phoneIsEqual = false;
        return true;
      } else {
        this.sendPhoneUpdate(payload);
      }
    },
    async sendInfoData(payload) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            id: payload.id,
            name: payload.name,
            birthdate: payload.birthdate,
            rg: payload.rg,
            email: payload.email,
            membership: payload.membership,
            address: {
              street: payload.street,
              number: payload.number,
              neighbourhood: payload.neighbourhood,
              city: payload.city,
              complement: payload.complement,
              cep: payload.cep,
              uf: payload.uf
            }
          }
        });
        this.$notify.success({
          message: "Dados atualizados"
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
      this.editInfoDialog = false;
    },
    async sendPhoneUpdate(payload) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            phone: payload.phone,
            phone_confirmed: false
          }
        });
        this.$notify.success({
          message: "Telefone atualizado"
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
      this.phoneForm.phone = null;
      this.editPhoneDialog = false;
    },
    async generateToken() {
      this.tokenIsGenerated = true;
      try {
        await this.$store.dispatch(types.CLIENT_CONFIRM_PHONE, {
          phone: phoneHelper.unformat(this.client.phone.replace("+55", "55")),
          userId: this.client.id
        });
        this.$notify.success({ title: "Código enviado!" });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handlePhoneValidation() {
      try {
        await this.$store.dispatch(types.CLIENT_TOKEN, {
          token: this.tokenCode,
          userID: this.client.id
        });
        await this.$store.dispatch(types.CLIENT_UPDATE, {
          user: {
            id: this.client.id,
            phone_confirmed: true
          }
        });
        this.$notify.success("Validado com sucesso !");
        this.showConfirmNumberDialog = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        } else if (error.status === 404) {
          this.$notify.error("Token Inválido");
        } else {
          this.$notify.error({ title: error.message });
        }
      }
    },
    async sendObservationText() {
      const { clientID } = this.$route.params;
      this.loading = true;
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE_STORE, {
          id: this.client.store_customer_id,
          storePayload: {
            store_customer: {
              description: this.observationText
            }
          }
        });
        await this.$store.dispatch(types.CLIENT, clientID);
        this.$notify.success({
          message: "Dados atualizados"
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
      this.editInfoDialog = false;
    },
    closeEditInfo() {
      this.form = {
        name: "",
        birthdate: "",
        rg: "",
        email: "",
        membership: "",
        street: "",
        number: null,
        neighbourhood: "",
        city: "",
        complement: "",
        cep: "",
        uf: ""
      };
      this.locationInformation = false;
      this.editInfoDialog = false;
    },
    async handleSecundaryPhoneUpdate() {
      this.loading = true;
      const payload = {
        user: {
          id: this.client.id,
          phones: [
            {
              phone: `+55${this.phoneSecundaryForm.phone.replace(
                /[()-\s]/g,
                ""
              )}`,
              description: this.phoneSecundaryForm.description
            }
          ]
        }
      };
      try {
        await this.$store.dispatch(types.CLIENT_UPDATE, payload);
        this.$notify.success({
          message: "Telefone Cadastrado"
        });
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
      this.editSecundaryPhoneDialog = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  margin-bottom: 16px;
  p + p {
    margin-top: 8px;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 100%;
  }
}
.observation-grid {
  margin: 16px 0;
  border: 1px solid rgba(108, 115, 124, 0.4);
  border-radius: 5px;
  padding: 1rem;
  p + p {
    margin-top: 8px;
  }
  .observation-box {
    margin-top: 1rem;
  }
}
.btn-grid {
  display: grid;
  @media screen and(max-width: 768px) {
    grid-template-columns: 1fr;
  }
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;
}
h2,
.el-switch {
  margin-bottom: 1rem;
}
.tip {
  background-color: rgba(255, 94, 68, 0.3);
  padding: 0.5rem;
  border-radius: 5px;
  border-left: 5px solid rgb(255, 94, 68);
  margin-bottom: 1rem;
}
.isConfirmed {
  color: rgb(103, 194, 58);
}
.isntConfirmed {
  color: rgb(255, 94, 68);
}
.token-input {
  margin-bottom: 1rem;
}
.svg-icon {
  width: 26px;
  height: 26px;
  @include medium-down {
    height: 21px;
  }
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.3s;
  &.disabled {
    cursor: not-allowed;
    box-shadow: none !important;
    opacity: 0.5;
  }
  &:hover {
    @include box-shadow(1);
  }
}
</style>
