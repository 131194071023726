<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px',
      }"
      style="width: 100%"
    />
  </skeleton-loading>
  <table-component
    v-else
    :columns="columns"
    :data="relations"
  />
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from 'moment';

import TableComponent from '@/components/Table';

export default {
  components: {
    TableComponent
  },
  data() {
    const notificationStatus = (status) => {
      const statuses = {
        sent: "Enviada",
        not_sent: "Não enviada",
        error: "Número inválido"
      };
      return statuses[status];
    };
    const notificationTypes = (type) => {
      const types = {
        late_statement: "Atraso",
        user_token: "Token",
        payment_received: "Pagamento",
        phone_confirmation: "Confirm. telefone",
        statement_due_date: "Vencimento",
        welcome_user: "Boas vindas",
        statement_cutoff: "Data de corte",
        store_customer_accepted: "Aceite",
        welcome_user_app_link: "App link",
        payment_promotion: "Promoção",
        sale_notification: "Venda"
      };
      return types[type];
    }
    return {
      loading: true,
      columns: [
        {
          key: 'date',
          label: 'Data',
          render: date => date ? moment(date).format('DD/MM/YYYY[ ]HH:MM:SS') : '',
        },
        {
          label: 'Status',
          render: item => item.notification && item.notification.status ? notificationStatus(item.notification.status) : '',
        },
        {
          label: 'Tipo',
          render: item => item.notification && item.notification.type ? notificationTypes(item.notification.type) : '',
        },
        {
          label: 'Mensagem',
          maxWidth: '200px',
          align: 'left',
          render: item => `<span>${item.notification.message}</span>`,
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      relations: types.CLIENT_RELATION_ACTIVITIES,
    }),
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(types.CLIENT_ACTIVITIES, this.$route.params.clientID)
    .catch((error) => {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    });
    this.loading = false;
  },
}
</script>

<style lang="scss" scoped>

</style>
