<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
				bottom: '15px',
				height: '15px',
				top: '20px',
			}"
      style="width: 100%"
    />
  </skeleton-loading>
  <div v-else style="width: 100%; overflow: auto;">
    <el-dialog :fullscreen="$isMobile" :visible.sync="showPaymentDealDialog">
      <section v-if="paymentDealDetails" class="collapse-header">
        <h2>REPARCELAMENTO</h2>
        <div>
          <div class="wrapper">
            <span>Data do pagamento</span>
            <span
              class="content"
            >{{ paymentDealDetails.payment_date ? $d(new Date(paymentDealDetails.payment_date), 'sale_summary') : '' }}</span>
          </div>
          <div class="wrapper">
            <span>Total</span>
            <span class="content">{{ $n(paymentDealDetails.amount, 'currency') }}</span>
          </div>
        </div>
      </section>
      <deal-payment-list :data="paymentDealDetails" :has-actions="false" />
    </el-dialog>
    <el-dialog :fullscreen="$isMobile" :visible.sync="showSaleDealDialog">
      <section v-if="saleDetails" class="collapse-header">
        <h2>REPARCELAMENTO</h2>
        <div>
          <div class="wrapper">
            <span>Data do reparcelamento</span>
            <span class="content">{{ saleDetails.sale_date | formatDate }}</span>
          </div>
          <div class="wrapper">
            <span>Total</span>
            <span class="content">{{ $n(saleDetails.amount, 'currency') }}</span>
          </div>
        </div>
      </section>
      <deal-payment-list :data="saleDetails" :has-actions="false" />
    </el-dialog>
    <el-dialog
      @destroy-on-close="true"
      @close="handleCloseSaleDialog"
      :fullscreen="$isMobile"
      :visible.sync="saleDialog"
    >
      <section
        v-if="saleDetails && typeof saleDetails.original_sale_id === 'string'"
        class="original-sale-text"
      >
        <el-alert
          v-if="originalSaleButton"
          title="Essa venda foi reparcelada, para consultar a venda original clique no botão ao lado."
          type="warning"
        ></el-alert>
        <el-alert v-else title="Essa é a venda que foi reparcelada." type="success"></el-alert>
        <br />
        <el-button
          size="mini"
          type="success"
          :disabled="!originalSaleButton"
          @click="handleOriginalSale(saleDetails.original_sale_id)"
        >Venda original</el-button>
        <el-button
          size="mini"
          type="success"
          :disabled="originalSaleButton"
          @click="handleLastSale(lastSale)"
        >Venda atual</el-button>
      </section>
      <br />
      <section v-if="saleDetails" class="collapse-header">
        <h2 :class="{originalSale: typeof saleDetails.original_sale_id === 'string'}">VENDA</h2>
        <div class="wrapper">
          <span>Data da venda</span>
          <span class="content">{{ saleDetails.sale_date | formatDate }}</span>
        </div>
        <div class="wrapper">
          <span>{{ 'Total' }}</span>
          <span class="content">{{ $n(saleDetails.amount, 'currency') }}</span>
        </div>
        <div class="wrapper">
          <span>Valor da Entrada</span>
          <span
            class="content"
          >{{ saleDetails.down_payments[0] ? $n(saleDetails.down_payments[0].amount, 'currency') : $n(0, 'currency') }}</span>
        </div>
      </section>
      <sale-list :data="saleDetails" :has-actions="false" />
    </el-dialog>
    <el-dialog :fullscreen="$isMobile" :visible.sync="receiptDialog">
      <template slot="title">
        <h2 class="dialog-title">RECEBIMENTO</h2>
      </template>
      <section v-if="receiptDetails" class="payment-header">
        <div class="content-wrapper">
          <div class="wrapper">
            <span>Data do Pagamento</span>
            <span class="content">{{ receiptDetails.payment_date | formatDate }}</span>
          </div>
          <div class="wrapper">
            <span>Identificador</span>
            <span class="content">{{ receiptDetails.identifier }}</span>
          </div>
          <div class="wrapper">
            <span>Desconto</span>
            <span class="content">{{ $n(receiptDetails.discount, 'currency') }}</span>
          </div>
          <div class="wrapper">
            <span>Total pago</span>
            <span class="content">{{ $n(receiptDetails.amount, 'currency') }}</span>
          </div>
          <div class="wrapper">
            <span>Juros</span>
            <span class="content">{{ receiptDetails.interest }}%</span>
          </div>
          <div class="wrapper">
            <span>Operador</span>
            <span class="content">{{ receiptDetails.operator ? receiptDetails.operator.name : '' }}</span>
          </div>
        </div>
      </section>
    </el-dialog>
    <el-dialog :fullscreen="$isMobile" :visible.sync="showConfirmationDialog">
      <div style="text-align: center;">
        <h3>Tem certeza que deseja cancelar {{ toCancelType === 'sale' ? 'essa venda' : 'esse recebimento' }}?</h3>
        <br />
        <el-button type="danger" @click="handleSalePaymentCancel">Sim, quero cancelar</el-button>
        <el-button type="secondary" @click="showConfirmationDialog = false">Voltar</el-button>
      </div>
    </el-dialog>
    <el-dialog
      @close="handleCloseRepayDialog"
      @destroy-on-close="true"
      :center="true"
      :fullscreen="$isMobile ? true : false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="showRepayDialog"
    >
      <h1 class="repay-dialog-title">Reparcelar venda</h1>
      <RepaySaleDetails v-if="repayStep" @repaySummary="handleSummary" :sale-data="saleData" />
      <RepaySaleSummary
        @repaySaleStepBack="handleRepaySaleBack"
        @repaySaleReceipt="handleRepaySaleReceipt"
        :sale-data="saleData"
        v-if="repaySummaryStep"
      />
      <RepaySaleReceipt v-if="repayReceiptStep" />
    </el-dialog>
    <table-component :columns="columns" :data="activities" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";

import TableComponent from "@/components/Table";

import toMoney from "@/helpers/toMoney";

import SaleList from "@/components/Sale/SaleSelector";
import DealPaymentList from "@/components/Deal/DealPaymentSelector";
import RepaySaleDetails from "@/containers/RepaySale/Details";
import RepaySaleSummary from "@/containers/RepaySale/Summary";
import RepaySaleReceipt from "@/containers/RepaySale/Receipt";

export default {
  components: {
    TableComponent,
    DealPaymentList,
    SaleList,
    RepaySaleDetails,
    RepaySaleSummary,
    RepaySaleReceipt
  },
  filters: {
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : "")
  },
  data() {
    const handleType = item => {
      let formatedType = "";
      switch (item.type) {
        case "sale":
          formatedType = "Venda";
          break;
        case "payment":
          formatedType = "Recebimento";
          break;
        case "payment_deal":
          formatedType = "Receb. do reparcelamento";
          break;
        case "sale_deal":
          formatedType = "Reparcelamento";
          break;
        default:
          formatedType = "";
      }
      if (
        formatedType === "Venda" &&
        typeof item.sale != "undefined" &&
        item.sale.original_sale_id
      ) {
        formatedType = "Venda reparcelada";
      }
      return formatedType;
    };
    return {
      loading: true,
      showPaymentDealDialog: false,
      showSaleDealDialog: false,
      showConfirmationDialog: false,
      showRepayDialog: false,
      receiptDialog: false,
      paymentDialog: false,
      saleDialog: false,
      toCancelID: undefined,
      toCancelType: undefined,
      saleData: {},
      repayStep: true,
      repaySummaryStep: false,
      repayTokenStep: false,
      repayReceiptStep: false,
      originalSaleButton: true,
      columns: [
        {
          key: "actions",
          label: "Ações",
          offSet: 140,
          options: [
            {
              label: "Detalhes",
              onPress: async item => {
                try {
                  const action =
                    item.type === "sale" || item.type === "sale_deal"
                      ? types.SALE_MORE_DETAILS
                      : types.PAYMENT_MORE_DETAILS;
                  await this.$store.dispatch(action, item.id);
                  await this.$store.dispatch(types.SALE_LAST_SALE, item.id);
                  if (item.type === "payment_deal") {
                    this.$nextTick(() => {
                      this.showPaymentDealDialog = true;
                    });
                  } else if (item.type === "payment") {
                    this.$nextTick(() => {
                      this.receiptDialog = true;
                    });
                  } else if (item.type === "sale_deal") {
                    this.$nextTick(() => {
                      this.showSaleDealDialog = true;
                    });
                  } else {
                    this.$nextTick(() => {
                      this[`${item.type}Dialog`] = true;
                    });
                  }
                } catch (error) {
                  if (this.$raven && error.status === 500) {
                    this.$raven.captureException(error.message, { req: error });
                  }
                  this.$notify.error({ title: error.message });
                }
              }
            },
            {
              label: "Ver recibo",
              onPress: async item => {
                try {
                  const action =
                    item.type === "sale" || item.type === "sale_deal"
                      ? types.SALE_MORE_DETAILS
                      : types.PAYMENT_MORE_DETAILS;
                  if (this.authOrigin === "app") {
                    return NativeAppChannel.postMessage(
                      `receipt/${action}/${item.id}/${this.$route.params.clientID}`
                    );
                  }
                  await this.$store.dispatch(action, item.id);
                  this.$router.push({
                    name: "Recibo do Cliente",
                    params: {
                      type: item.type
                    }
                  });
                } catch (error) {
                  if (this.$raven && error.status === 500) {
                    this.$raven.captureException(error.message, { req: error });
                  }
                  this.$notify.error({ title: error.message });
                }
              }
            },
            {
              label: "Reparcelar",
              disabled: item => !item.sale || !item.sale.active,
              onPress: item => {
                this.showRepayDialog = true;
                this.repayStep = true;
                this.saleData = item;
              }
            },
            {
              label: "Cancelar",
              disabled: item =>
                (item.payment && item.payment.payment_type === "billet") ||
                (item.payment &&
                  !this.auth_details?.permissions?.cancel_payment) ||
                (item.sale && !this.auth_details?.permissions?.cancel_sale) ||
                item[item.type.replace("_deal", "")].active === false,
              onPress: item => {
                this.showConfirmationDialog = true;
                this.toCancelID = item.id;
                this.toCancelType = item.type;
              }
            }
          ]
        },
        {
          label: "#",
          width: "60px",
          render: item =>
            item.payment
              ? `<span>${item.payment.identifier}</span>`
              : `<span>${item.sale.identifier}</span>`
        },
        {
          key: "date",
          label: "Data",
          render: date =>
            `<span>${moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>`
        },
        {
          label: "Tipo",
          render: item => `<span>${handleType(item)}</span>`
        },
        {
          key: "amount",
          width: "160px",
          label: "Valor",
          render: amount =>
            `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(
              amount
            )}<span>`
        },
        {
          labe: "",
          width: "30px",
          render: item =>
            item.sale ? `<span>${item.sale.installments_number}x</span>` : ""
        },
        {
          label: "Operador",
          render: item =>
            item.payment
              ? `<span>${
                  item.payment.operator ? item.payment.operator.name : ""
                }</span>`
              : `<span>${
                  item.sale.operator ? item.sale.operator.name : ""
                }</span>`
        },
        {
          label: "Filial",
          render: item =>
            item.payment
              ? `<span>${
                  item.payment.store_branch
                    ? item.payment.store_branch.name
                    : ""
                }</span>`
              : `<span>${
                  item.sale.store_branch ? item.sale.store_branch.name : ""
                }</span>`
        },
        {
          label: "Status",
          render: item =>
            item.payment
              ? `<span>${
                  item.payment.active ? "Ativo(a)" : "Cancelado(a)"
                }</span>`
              : `<span>${item.sale.active ? "Ativo(a)" : "Cancelado(a)"}</span>`
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      authOrigin: types.AUTH_ORIGIN,
      activities: types.CLIENT_ACTIVITIES,
      client: types.CLIENT_DETAILS,
      paymentDealDetails: types.PAYMENT_MORE_DETAILS,
      saleDetails: types.SALE_MORE_DETAILS,
      receiptDetails: types.PAYMENT_MORE_DETAILS,
      dealDetails: types.DEAL_MORE_DETAILS,
      auth_details: types.AUTH_ACTIVE_STORE_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      lastSale: types.SALE_LAST_SALE
    })
  },
  async created() {
    this.loading = true;
    if (!this.client.store_customer_id) {
      const { clientID } = this.$route.params;
      await this.$store.dispatch(types.CLIENT, clientID);
    }
    try {
      await this.$store.dispatch(
        types.CLIENT_ACTIVITIES,
        this.$route.params.clientID
      );
      await this.$store.dispatch(types.STORE_PLANS, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  },
  methods: {
    async handleSalePaymentCancel() {
      try {
        const action =
          this.toCancelType === "sale" || this.toCancelType === "sale_deal"
            ? types.SALE_CANCEL
            : this.toCancelType === "payment" ||
              this.toCancelType === "payment_deal"
            ? types.PAYMENT_CANCEL
            : false;
        if (action) {
          await this.$store.dispatch(action, this.toCancelID);
        }
        await this.$store.dispatch(types.CLIENT, this.$route.params.clientID);
        this.loading = true;
        await this.$store.dispatch(
          types.CLIENT_ACTIVITIES,
          this.$route.params.clientID
        );
        this.loading = false;
        this.$notify.success({
          title: "Cancelamento realizado com sucesso!"
        });
        this.toCancelID = undefined;
        this.toCancelType = undefined;
        this.showConfirmationDialog = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    handleSummary() {
      this.repayStep = false;
      this.repaySummaryStep = true;
    },
    handleRepaySaleBack() {
      this.repayStep = true;
      this.repaySummaryStep = false;
    },
    handleRepaySaleReceipt() {
      this.repaySummaryStep = false;
      this.repayReceiptStep = true;
    },
    async handleCloseRepayDialog() {
      this.repaySummaryStep = false;
      this.repayTokenStep = false;
      this.repayReceiptStep = false;
      this.loading = true;
      try {
        await this.$store.dispatch(
          types.CLIENT_ACTIVITIES,
          this.$route.params.clientID
        );
        await this.$store.dispatch(types.STORE_PLANS, this.storeID);
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.loading = false;
    },
    async handleOriginalSale(originalSaleID) {
      try {
        await this.$store.dispatch(types.SALE_MORE_DETAILS, originalSaleID);
        this.originalSaleButton = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleLastSale(sale) {
      try {
        await this.$store.dispatch(types.SALE_MORE_DETAILS, sale);
        this.originalSaleButton = true;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    },
    async handleCloseSaleDialog() {
      await this.$store.dispatch(types.SALE_LAST_SALE, null);
      this.originalSaleButton = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-title {
  font-family: Montserrat;
  letter-spacing: 1.025px;
  font-weight: 700;
}
.collapse-header {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  align-items: center;
  padding-right: 14px;
  h2 {
    font-family: Montserrat;
    letter-spacing: 1.025px;
    font-weight: 700;
  }
  .wrapper {
    display: inline-block;
    height: 48px;
    margin: 6px 0 0 12px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      text-align: center;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
.payment-header {
  display: flex;
  flex-flow: column wrap;
  // align-items: center;
  .content-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    text-align: left;
    align-items: flex-start;
  }
  .wrapper {
    height: 48px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
.repay-dialog-title {
  font-weight: bold;
  color: $laranja;
  text-align: center;
}
.originalSale {
  color: $amarelo;
}
</style>
