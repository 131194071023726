<template>
  <div class="client-profile-header-container">
    <skeleton-loading v-if="loading">
      <div class="loading-wrapper">
        <circle-skeleton style="height: 200px; width: 200px;" />
        <square-skeleton
          :count="3"
          :box-properties="{
            bottom: '15px',
            height: '15px',
            top: '20px',
          }"
          style="width: 40%"
        />
        <circle-skeleton style="height: 200px; width: 200px;" />
      </div>
    </skeleton-loading>
    <section v-else>
      <div v-if="client" class="client-profile-header-wrapper">
        <!-- <div>
          <el-button :disabled="disableBackButton" class="button" type="secondary">
            <svgicon name="search" width="20" height="20" />
            <span class="medium-up">Nova busca</span>
          </el-button>

          <el-button
            type="secondary"
            @click="$router.push({ name: 'Cliente', params: { clientID: details.id } })"
          >
            <svgicon name="store" width="20" height="20" />
            <span class="medium-up">Menu do cliente</span>
          </el-button>

          <el-button :disabled="step === 0" class="button" type="secondary">
            <svgicon name="money-plus" width="20" height="20" />
            <span class="medium-up">Recomeçar</span>
          </el-button>
        </div>-->
        <!-- <div class="info-label-wrapper">
          <p class="info-label" style="font-weight: 700;">{{ client.name }}</p>
          <p class="info-label">{{ client.cpf | formatCPF }}</p>
          <p class="info-label">{{ client.phone | formatPhone }}</p>
          <p class="info-label">{{ client.email }}</p>
        </div>-->
        <div class="client-profile-header-avatar-wrapper">
          <ClientAvatar :url="client.selfie" />
        </div>
        <div class="info-label-wrapper">
          <p class="info-label" style="font-weight: 700;">{{ client.name }}</p>
          <p class="info-label">{{ client.cpf | formatCPF }}</p>
          <p class="info-label">{{ client.phone | formatPhone }}</p>
          <p class="info-label">{{ client.email }}</p>
        </div>
        <div class="tag-wrapper">
          <tag
            v-if="!isInquiry && client && client.store_status"
            :value="client.store_status.label.toUpperCase()"
            :color="storeStatuses(client.store_status)[0]"
            :text-color="storeStatuses(client.store_status)[1]"
            title="Status do cliente"
            :reason="client.store_status.description"
          />
          <tag
            v-if="(!isInquiry && client && is_neopag_credit_config)"
            :value="client.is_neopag_credit ? 'CRÉDITO NEOPAG' : 'CRÉDITO LOJA'"
            :color="client.is_neopag_credit ? '#98C187' : '#87BAC1'"
            :text-color="'#FFFFFF'"
            title="Tipo do cliente"
          />

          <tag
            v-if="client"
            :value="client.registered_in_app ? 'SIM' : 'NÃO'"
            :color="client.registered_in_app ? '#56C779' : '#FF0000'"
            title="Possui o app"
            text-color="#FFF"
          />
          <tag
            v-if="client"
            :value="documentsStatus(client.address_proof_status).label"
            :color="documentsStatus(client.address_proof_status).color"
            :reason="client.address_proof_approval_status && client.address_proof_approval_status.reason ? client.address_proof_approval_status.reason : ''"
            title="Comprovante de endereço"
            text-color="#FFF"
          />
          <tag
            v-if="client"
            :value="client.phone_confirmed ? 'SIM' : 'NÃO'"
            :color="client.phone_confirmed ? '#56C779' : '#FF0000'"
            title="Número do celular"
            text-color="#FFF"
          />
          <tag
            v-if="client"
            :value="documentsStatus(client.documents_sent).label"
            :color="documentsStatus(client.documents_sent).color"
            :reason="client.document_status && client.document_status.reason ? client.document_status.reason : ''"
            title="Documento"
            text-color="#FFF"
          />
          <tag
            v-if="client && client.income_proof_status !== 'ok'"
            slot="reference"
            :value="documentsStatus(client.income_proof_status).label"
            :color="documentsStatus(client.income_proof_status).color"
            :reason="client.income_proof_approval_status && client.income_proof_approval_status.reason ? client.income_proof_approval_status.reason : ''"
            title="Comprovante de renda"
            text-color="#FFF"
          />
          <el-popover
            v-else-if="client"
            :content="clientMoney"
            :trigger="$isMobile ? 'click' : 'hover'"
            placement="bottom"
            title="Renda"
            width="200"
          >
            <tag
              v-if="client"
              slot="reference"
              :value="documentsStatus(client.income_proof_status).label"
              :color="documentsStatus(client.income_proof_status).color"
              :reason="client.income_proof_approval_status && client.income_proof_approval_status.reason ? client.income_proof_approval_status.reason : ''"
              title="Comprovante de renda"
              text-color="#FFF"
            />
          </el-popover>
        </div>
        <div class="client-profile-header-avatar-wrapper">
          <radial-progress
            :diameter="200"
            :stroke-width="11"
            :completed-steps="userPercentageScore"
            :total-steps="100"
            :start-color="userPercentageColor"
            :stop-color="userPercentageColor"
            inner-stroke-color="rgba(108, 115, 124, 0.3)"
          >
            <!-- <ClientAvatar :url="client.selfie" /> -->
            <p class="score-label mini">Score: {{ scoreNeopag.neopag_score }}</p>
            <p class="score-label">
              Limite sugerido:
              <br />
              <span :style="{color: userPercentageColor }" class="money">R$</span>
              <span :style="{color: userPercentageColor }">{{ suggestedCredit }}</span>
              <br />
            </p>
          </radial-progress>
        </div>
      </div>
      <!-- <client-profile-kpis
        :client="client"
        :loading="loading"
      />-->
    </section>
  </div>
</template>

<script>
import RadialProgress from "vue-radial-progress";

import ClientAvatar from "@/components/ClientAvatar";
import Tag from "@/components/Tag";

import toCPF from "@/helpers/toCPF";
import { format } from "@/helpers/phone";
import toMoney from "@/helpers/toMoney";
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      is_neopag_credit_config: false,
    };
  },
  components: {
    ClientAvatar,
    Tag,
    RadialProgress,
  },
  filters: {
    formatCPF: cpf => (cpf ? toCPF(cpf) : ""),
    formatPhone: phone => (phone ? format(phone.replace("+55", "")) : ""),
    formatMoney: amount => (amount ? toMoney(toMoney) : "")
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    client: {
      type: [Object, Boolean],
      default: () => {}
    },
    scoreNeopag: {
      type: [Object, Boolean],
      default: () => {}
    },
    isInquiry: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userPercentageScore() {
      const { neopag_score } = this.scoreNeopag;
      if (this.scoreNeopag && neopag_score) {
        const score = (neopag_score * 100) / 60000; // percentage score where 60.000 is equal to 100%;
        return score;
      }
      return 0;
    },
    userPercentageColor() {
      const { level } = this.scoreNeopag;
      if (this.scoreNeopag && level) {
        if (level <= 2) {
          return "rgba(151, 4, 12, 0.7)";
        } else if (level > 2 && level <= 5) {
          return "rgba(253, 152, 39, 0.7)";
        } else {
          return "rgba(108, 167, 84, 0.7)";
        }
      }
      return "rgba(151, 4, 12, 0.7)";
    },
    suggestedCredit() {
      if (this.scoreNeopag && this.scoreNeopag.recommended_credit) {
        const amount = toMoney(this.scoreNeopag.recommended_credit);
        return amount.replace("R$", "").replace(" ", "");
      }
      return "0,00";
    },
    clientMoney() {
      if (this.client && this.client.neopag_estimated_income) {
        return toMoney(this.client.neopag_estimated_income);
      }
      return toMoney(0);
    },
        ...mapGetters({
      config: types.STORE_CONFIG,
      storeID: types.AUTH_ACTIVE_STORE
    })
  },

  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_CONFIG, this.storeID);
      this.is_neopag_credit_config = this.config.is_neopag_credit_config;

    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  },
  methods: {
    documentsStatus(status) {
      const statuses = {
        not_sent: {
          label: "NÃO ENVIADO",
          color: "#6C737C"
        },
        sent: {
          label: "AGUARDANDO ANÁLISE",
          color: "#005C67"
        },
        ok: {
          label: "APROVADO",
          color: "#56C779"
        },
        invalid: {
          label: "REPROVADO",
          color: "#f1565d"
        }
      };
      if (statuses[status]) {
        return statuses[status];
      }
      return {};
    },
    storeStatuses(storeStatus) {
      const colors = {
        1: ["#56C779", "#fff"],
        2: ["#000", "#fff"],
        3: ["#1cbeca", "#000"],
        4: ["#1cbeca", "#fff"],
        5: ["#f1e88c", "#6c737c"],
        6: ["#f1565d", "#fff"],
        7: ["#f1565d", "#fff"],
        8: ["#6c737c", "#fff"],
        9: ["#f1565d", "#fff"],
        10: ["#f1e88c", "#6c737c"],
        11: ["#f1e88c", "#6c737c"],
        12: ["#6c737c", "#fff"],
        13: ["#000", "#fff"],
        99: ["#56C779", "#fff"]
      };
      return colors[storeStatus.id];
    },
    formatMoney(amount) {
      if (amount) {
        return toMoney(amount);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  .square-list {
    margin: 0 16px;
  }
  @media screen and (max-width: 425px) {
    justify-content: center;
    .square-list {
      margin-left: 0;
    }
  }
}
.client-profile-header-container {
  width: 100%;
}
.client-profile-header-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .info-label-wrapper {
    margin: 0 4rem 22px 16px;
    text-align: center;
  }
  .info-label {
    max-width: 320px;
    font-family: Montserrat;
    font-size: 1rem;

    & + p {
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-flow: row wrap;
    justify-content: center;
    .info-label-wrapper {
      text-align: center;
      margin: 0 0 52px 16px;
    }
    .client-profile-header-avatar-wrapper {
      margin-bottom: 32px;
    }
  }
}
.tag-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  margin-bottom: 18px;
  align-items: flex-end;
  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.score-label {
  align-self: center;
  font-family: "Montserrat";
  letter-spacing: 1.125px;
  color: $preto;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  span {
    align-self: center;
    font-size: 22px;
    font-weight: 700;
    color: rgba(108, 167, 84, 0.5);
  }
  .money {
    font-size: 11px;
  }
}
.mini {
  font-size: 11px;
  margin: 0 0 8px;
}
</style>
