<template>
  <div class="container">
    <span class="label">{{ label }}</span>
    <span class="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding: 8px;
    border-radius: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(108, 115, 124, 0.1);
    color: rgba(108, 115, 124, 1);
    .label {
      font-size: 13px;
      text-align: center;
    }
    .value {
      font-size: 18px;
      padding-top: 4px;
      font-weight: 700;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
</style>
