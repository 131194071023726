<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
				bottom: '15px',
				height: '15px',
				top: '20px'
			}"
      style="width: 100%"
    />
  </skeleton-loading>
  <section v-else>
    <el-dialog :visible.sync="billetActionsModal" title="Detalhes" class="billet-reports-modal">
      <h4>Linha digitavel</h4>
      <h3>{{ billetInformation.barcode }}</h3>
      <el-button
        v-if="billetInformation.url"
        size="small"
        @click="handleLink(billetInformation.url)"
      >Abrir link</el-button>
      <el-button
        v-if="billetInformation.barcode"
        size="small"
        type="success"
        @click="copyToClipboard(billetInformation.barcode)"
      >Copiar código</el-button>
      <el-button
        :disabled="billetInformation.status != 'registered'"
        size="small"
        type="primary"
        @click="handleCancelBillet(billetInformation.id)"
      >Cancelar boleto</el-button>
    </el-dialog>
    <table-component :columns="columns" :data="looseBillets" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from "moment";

import TableComponent from "@/components/Table";

import toMoney from "@/helpers/toMoney";

export default {
  components: {
    TableComponent
  },
  filters: {
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : "")
  },
  data() {
    return {
      loading: true,
      redoing: false,
      billetActionsModal: false,
      redoDueDate: "",
      billetInformation: {},
      columns: [
        {
          key: "due_date",
          label: "Data",
          width: "100px",
          render: due_date =>
            due_date ? moment(due_date).format("DD/MM/YYYY") : ""
        },
        {
          key: "amount",
          label: "Valor Total",
          render: amount =>
            `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(
              amount
            )}<span>`
        },
        {
          key: "paid_amount",
          label: "Valor Pago",
          render: paid_amount =>
            `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(
              paid_amount
            )}<span>`
        },
        {
          key: "paid_at",
          label: "Data do pagamento",
          render: paid_at =>
            paid_at ? moment(paid_at).format("DD/MM/YYYY") : "Não foi pago"
        },
        {
          key: "status",
          label: "Status",
          render: status => `<span>${this.handleStatusLabel(status)}</span>`
        },
        {
          key: "actions",
          label: "Ações",
          offSet: -100,
          options: [
            {
              label: "Ver boleto",
              onPress: async item => {
                this.billetActionsModal = true;
                this.billetInformation = item;
              }
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      looseBillets: types.CLIENT_LOOSE_BILLS,
      client: types.CLIENT_DETAILS
    })
  },
  async mounted() {
    this.loading = true;
    if (!this.client.store_customer_id) {
      const { clientID } = this.$route.params;
      await this.$store.dispatch(types.CLIENT, clientID);
    }
    try {
      await this.$store.dispatch(types.CLIENT_LOOSE_BILLS, {
        storeCustomerID: this.client.store_customer_id
      });
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
    this.loading = false;
  },
  methods: {
    copyToClipboard(barcode) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then(async result => {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard
              .writeText(barcode)
              .then(() =>
                this.$notify({
                  title: "Copiado!",
                  message: "Código copiado com sucesso",
                  type: "success"
                })
              )
              .catch(() =>
                this.$notify.error({
                  title: "Erro!",
                  message: "Não foi possivel copiar o código de barras"
                })
              );
          }
        });
    },
    async handleLink(link) {
      window.open(link, "_blank");
    },
    handleStatusLabel(status) {
      const labels = {
        pending: "Pendente",
        registered: "Registrado",
        paid: "Pago",
        canceled: "Cancelado",
        expired: "Expirado"
      };
      return labels[status];
    },
    async handleCancelBillet(billet_id) {
      const payload = {
        billet_id,
        client_customer_id: this.client.store_customer_id
      };
      try {
        await this.$store.dispatch(types.BILLET_CANCEL, payload);
        this.$notify.success({
          message: "Cancelado com sucesso"
        });
        this.billetActionsModal = false;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-header {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  align-items: center;
  padding-right: 14px;
  h2 {
    font-family: Montserrat;
    letter-spacing: 1.025px;
    font-weight: 700;
  }
  .wrapper {
    display: inline-block;
    height: 48px;
    margin: 6px 0 0 12px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      text-align: center;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
.billet-reports-modal {
  text-align: center;
  .el-dialog {
    .el-dialog__body {
      h3 {
        margin: 6px 0 32px 0;
      }
    }
  }
}
</style>
