var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column.key ? column.key : Math.random() * 100,staticClass:"table-head",style:({
            textAlign: column.align ? column.align : 'center'
          })},[_c('span',{staticClass:"table-span__head"},[_vm._v(_vm._s(column.label))])])}),0)]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.id,staticClass:"table-row"},_vm._l((_vm.columns),function(column){return _c('td',{key:column.key ? column.key : Math.random() * 100,staticClass:"table-data",style:({
            textAlign: column.align ? column.align : 'center',
            width: column.width ? column.width : 'auto',
            minWidth: column.width ? column.width : '140px',
            maxWidth: column.maxWidth ? column.maxWidth : '140px',
            opacity: column.opacity ? column.opacity : 1
          })},[(column.key === 'actions')?_c('div',[_c('action-button',{attrs:{"disabled":column.disabled ? column.disabled(item) : false,"options":column.options,"item":item,"off-set":column.offSet}})],1):(column.key && column.render)?_c('span',{staticClass:"table-span",domProps:{"innerHTML":_vm._s(column.render(item[column.key]))}}):(!column.key && column.render)?_c('span',{staticClass:"table-span",domProps:{"innerHTML":_vm._s(column.render(item))}}):(column.key && !column.render)?_c('span',{staticClass:"table-span"},[_vm._v(" "+_vm._s(item[column.key])+" ")]):_vm._e()])}),0)}),0)]),_c('div',{staticClass:"mobile-wrapper"},_vm._l((_vm.data),function(item){return _c('el-card',{key:item.id},[_c('section',{staticClass:"mobile-content"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.key ? column.key : Math.random() * 100,staticClass:"mobile-item"},[_c('span',{staticClass:"mobile-label"},[_vm._v(" "+_vm._s(column.label)+" ")]),(column.key === 'actions')?_c('div',[_c('action-button',{attrs:{"disabled":column.disabled ? column.disabled(item) : false,"options":column.options,"item":item,"off-set":column.offSet,"mobile":true}})],1):(column.key && column.render)?_c('span',{staticClass:"mobile-span",domProps:{"innerHTML":_vm._s(column.render(item[column.key]))}}):(!column.key && column.render)?_c('div',{staticClass:"mobile-span",domProps:{"innerHTML":_vm._s(column.render(item))}}):(column.key && !column.render)?_c('span',{staticClass:"mobile-span"},[_vm._v(" "+_vm._s(item[column.key])+" ")]):_vm._e()])}),0)])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }