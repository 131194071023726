<template>
  <skeleton-loading v-if="loading">
    <square-skeleton
      :count="10"
      :box-properties="{
        bottom: '15px',
        height: '15px',
        top: '20px',
      }"
      style="width: 100%"
    />
  </skeleton-loading>
  <table-component
    v-else
    :columns="columns"
    :data="statements"
  />
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import moment from 'moment';

import TableComponent from '@/components/Table';
import toMoney from "@/helpers/toMoney"

export default {
  components: {
    TableComponent
  },
  data() {
    return {
      loading: true,
      columns: [
        {
          key: 'date',
          label: 'Data',
          width: '80px',
          render: date => date ? moment(date).format('DD/MM/YYYY') : '',
        },
        {
          key: 'description',
          label: 'Descrição',
        },
        {
          key: 'amount',
          label: 'Valor',
          align: 'right',
          render: amount => `<span style="font-family: Roboto Mono; font-weight: 700;">${toMoney(amount)}<span>`
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      details: types.CLIENT_DETAILS,
    }),
    statements() {
      const statements = [];
      if (this.details.user_statements) {
        if (this.details.user_statements.open_statement) {
          statements.push(...this.details.user_statements.open_statement.items);
        }
        if (this.details.user_statements.future_statement) {
          statements.push(
            ...this.details.user_statements.future_statement.items
          );
        }
      }
      return statements;
    }
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch(types.CLIENT, this.$route.params.clientID)
    .catch((error) => {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    });
    this.loading = false;
  },
}
</script>

<style lang="scss" scoped>

</style>
